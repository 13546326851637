import React, { useState } from "react";
import ListOfTemplates from "./listOfTemplates";
import EditTemplate from "./editTemplate";

function EmailTemplate() {
  const [selectedTemplate, setSelectedTemplate] = useState();

  const handleTemplate = (id) => {
    setSelectedTemplate(id);
  };

  return (
    <div className="content">
      <div className="container-fluid">
        {!selectedTemplate ? (
          <ListOfTemplates handleTemplate={handleTemplate} />
        ) : (
          <EditTemplate
            handleTemplate={handleTemplate}
            templateId={selectedTemplate}
          />
        )}
      </div>
    </div>
  );
}

export default EmailTemplate;
