import React, { useEffect, useState } from "react";
import { listLocations } from "../../services/locations";

function EditLocation({ appoint, updateData }) {
  const [editLoc, setEditLoc] = useState(false);
  const [data, setData] = useState({});
  const [locationsData, setLocationsData] = useState([]);

  const fetchLocations = async (id_doc) => {
    try {
      const data = await listLocations(id_doc);
      let locList = [{ id: 0, name: "IME by Video Link" }];
      data.map((item) =>
        locList.push({
          id: item.id,
          name: `${item.state} - ${item.city} / Postcode: ${item.postcode}`,
        })
      );
      setLocationsData(locList);
    } catch (error) {
      console.log(error);
    }
  };

  const refreshData = () => {
    setData({
      by_video: appoint.appoint.by_video,
      state: appoint.location.state || "",
      city: appoint.location.city || "",
      postcode: appoint.location.postcode || "",
      id_location: appoint.appoint.id_location || 0,
      id_doc: appoint.appoint.id_doc,
      newData: parseInt(appoint.appoint.id_location) || 0,
    });
    fetchLocations(appoint.appoint.id_doc);
  };

  useEffect(() => {
    refreshData();
  }, []);

  useEffect(() => {
    refreshData();
  }, [appoint]);

  const handleUpdateSelect = (value) => {
    setData({ ...data, newData: parseInt(value) });
  };

  const handleUpdateData = () => {
    let updateDataObj = {};

    if (data.newData === 0) {
      updateDataObj = {
        by_video: 1,
        id_location: null,
      };
    } else {
      updateDataObj = {
        by_video: 0,
        id_location: data.newData,
      };
    }
    console.log(updateDataObj);
    updateData(updateDataObj);
    setEditLoc(false);
  };

  return (
    <div>
      {data && (
        <>
          {!editLoc ? (
            <>
              {data.by_video ? (
                <>
                  <u>Location:</u> <span class="oi oi-video"></span> IME by
                  Video Link
                </>
              ) : (
                <>
                  <u>Location:</u> {data.state || ""} - {data.city || ""} /
                  Postcode: {data.postcode || ""}
                </>
              )}
              <span
                className="badge badge-primary ml-2 pointer"
                style={{ fontSize: "1em" }}
                onClick={() => setEditLoc(true)}
              >
                Edit
              </span>
            </>
          ) : (
            <>
              <p>
                <u>Location:</u>
                <select
                  class="form-control"
                  value={data.newData}
                  onChange={(e) => handleUpdateSelect(e.target.value)}
                >
                  {locationsData.map((item, index) => (
                    <option value={item.id} key={index}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </p>
              <p>
                <span
                  className="btn btn-success mr-2"
                  onClick={handleUpdateData}
                >
                  Update
                </span>
                <span
                  className="btn btn-danger"
                  onClick={() => setEditLoc(false)}
                >
                  Cancel
                </span>
              </p>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default EditLocation;
