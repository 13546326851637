import React, { useEffect, useState } from "react";
import format from "date-format";
import {
  EditField,
  EditSelect,
  EditTextArea,
  EditMultiSelect,
} from "../../common/editField";
import { claimTypes } from "../../services/claimtypes";
import { injuries } from "../../services/injuries";

function EditClient({ appoint, updateData }) {
  const [edit, setEdit] = useState(false);
  const [data, setData] = useState();
  const [claimTypesData, setClaimTypesData] = useState([]);
  const [injuriesData, setInjuriesData] = useState([]);

  const fetchTypes = async () => {
    const data = await claimTypes();
    setClaimTypesData(data);

    const injuriesData = await injuries();
    setInjuriesData(injuriesData);
  };

  const refreshData = () => {
    setEdit(false);
    setData({
      name: appoint.client.name,
      phone: appoint.client.phone,
      email: appoint.client.email,
      dt_birth: appoint.client.dt_birth,
      accident_date: appoint.appoint.accident_date,
      claim_type: appoint.appoint.claim_type,
      injury_type: appoint.appoint.injury_type,
      home_visit_claimants_address:
        appoint.appoint.home_visit_claimants_address,
    });
  };

  useEffect(() => {
    refreshData();
    fetchTypes();
  }, []);

  const handleData = (id, value) => {
    setData({ ...data, [id]: value });
  };

  const handleMultipleSelect = (id, value) => {
    let currentData = [];
    if (data[id]) {
      currentData = data[id].split(",");
    }
    if (
      currentData &&
      currentData.findIndex((element) => element === value) !== -1
    ) {
      const index = currentData.indexOf(value);
      if (index > -1) {
        currentData.splice(index, 1);
      }
    } else {
      currentData.push(value);
    }
    setData({ ...data, [id]: currentData.toString() });
  };

  const handleUpdateData = (data) => {
    updateData(data);
    setEdit(false);
  };

  return (
    <div>
      {data && (
        <>
          <p>
            <strong>Client and Claim details</strong>
          </p>
          <div className="row">
            <div className="col-12 col-md-6 mt-2">
              <EditField
                edit={edit}
                initialValue={appoint.client.name}
                currentValue={data.name}
                setData={handleData}
                id="name"
                label="Name"
              />
            </div>

            <div className="col-12 col-md-6 mt-2">
              <EditField
                edit={edit}
                initialValue={appoint.client.phone}
                currentValue={data.phone}
                setData={handleData}
                id="phone"
                label="Phone"
              />
            </div>

            <div className="col-12 col-md-6 mt-2">
              <EditField
                edit={edit}
                initialValue={appoint.client.email}
                currentValue={data.email}
                setData={handleData}
                id="email"
                label="Email"
              />
            </div>

            <div className="col-12 col-md-6 mt-2">
              <EditField
                edit={edit}
                initialValue={format(
                  "dd/MM/yyyy",
                  new Date(appoint.client.dt_birth)
                )}
                currentValue={data.dt_birth}
                setData={handleData}
                id="dt_birth"
                type="date"
                label="Date of Birth"
              />
            </div>

            <div className="col-12 col-md-6 mt-2">
              <EditField
                edit={edit}
                initialValue={format(
                  "dd/MM/yyyy",
                  new Date(appoint.appoint.accident_date)
                )}
                currentValue={data.accident_date}
                setData={handleData}
                type="date"
                id="accident_date"
                label="Accident Date"
              />
            </div>

            <div className="col-12 col-md-6 mt-2">
              <EditSelect
                edit={edit}
                initialValue={appoint.appoint.claim_type}
                currentValue={data.claim_type}
                setData={handleData}
                id="claim_type"
                list={claimTypesData}
                label="Clain type"
              />
            </div>

            <div className="col-12 col-md-6 mt-2">
              <EditMultiSelect
                edit={edit}
                initialValue={appoint.appoint.injury_type}
                currentValue={data.injury_type.split(",")}
                setData={handleMultipleSelect}
                id="injury_type"
                list={injuriesData}
                label="Injury"
              />
            </div>

            <div className="col-12 col-md-6 mt-2">
              <EditTextArea
                edit={edit}
                initialValue={appoint.appoint.home_visit_claimants_address}
                currentValue={data.home_visit_claimants_address}
                setData={handleData}
                id="home_visit_claimants_address"
                label="Address Home Visit"
              />
            </div>

            <div className="col-12 col-md-6 mt-2">
              <EditTextArea
                edit={edit}
                initialValue={appoint.appoint.comments}
                currentValue={data.comments}
                setData={handleData}
                id="comments"
                label="Comments"
              />
            </div>
          </div>
          <div className="row">
            {!edit ? (
              <span
                className="badge badge-primary ml-3 mt-2 pointer"
                style={{ fontSize: "1em" }}
                onClick={() => setEdit(!edit)}
              >
                Edit
              </span>
            ) : (
              <div className="mt-3 ml-2">
                <span
                  class="btn btn-success mr-2"
                  onClick={() => handleUpdateData(data)}
                >
                  Update
                </span>
                <span class="btn btn-danger" onClick={() => refreshData(false)}>
                  Cancel
                </span>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default EditClient;
