import React, { useEffect, useState } from "react";
import {
  EditField,
  EditSelect,
  EditTextArea,
  EditMultiSelect,
} from "../../common/editField";

function EditLawFirm({ appoint, updateData }) {
  const [edit, setEdit] = useState(false);
  const [data, setData] = useState();

  const refreshData = () => {
    setEdit(false);
    setData({
      name: appoint.lawyer.name || "",
      reference: appoint.lawyer.reference || "",
      lawyer_name: appoint.lawyer.lawyer_name || "",
      lawyer_phone: appoint.lawyer.lawyer_phone || "",
      lawyer_email: appoint.lawyer.lawyer_email || "",
      lawyer_address: appoint.lawyer.lawyer_address || "",
    });
  };

  useEffect(() => {
    refreshData();
  }, []);

  const handleData = (id, value) => {
    setData({ ...data, [id]: value });
  };

  const handleUpdateData = (data) => {
    updateData(data);
    setEdit(false);
  };

  return (
    <div>
      {data && (
        <>
          <p>
            <strong>Law firm and Lawyer details</strong>
          </p>
          <div className="row">
            <div className="col-12 col-md-6">
              <EditField
                edit={edit}
                initialValue={appoint.lawyer.name}
                currentValue={data.name}
                setData={handleData}
                id="name"
                label="Name"
              />
            </div>
            <div className="col-12 col-md-6">
              <EditField
                edit={edit}
                initialValue={appoint.lawyer.reference}
                currentValue={data.reference}
                setData={handleData}
                id="reference"
                label="Reference"
              />
            </div>
            <div className="col-12 col-md-6">
              <EditField
                edit={edit}
                initialValue={appoint.lawyer.lawyer_name}
                currentValue={data.lawyer_name}
                setData={handleData}
                id="lawyer_name"
                label="Lawyer"
              />
            </div>
            <div className="col-12 col-md-6">
              <EditField
                edit={edit}
                initialValue={appoint.lawyer.lawyer_phone}
                currentValue={data.lawyer_phone}
                setData={handleData}
                id="lawyer_phone"
                label="Phone"
              />
            </div>
            <div className="col-12 col-md-6">
              <EditField
                edit={edit}
                initialValue={appoint.lawyer.lawyer_email}
                currentValue={data.lawyer_email}
                setData={handleData}
                id="lawyer_email"
                label="Email"
              />
            </div>
            <div className="col-12 col-md-6">
              <EditField
                edit={edit}
                initialValue={appoint.lawyer.lawyer_address}
                currentValue={data.lawyer_address}
                setData={handleData}
                id="lawyer_address"
                label="Address"
              />
            </div>
          </div>
          <div className="row">
            {!edit ? (
              <span
                className="badge badge-primary ml-3 mt-2 pointer"
                style={{ fontSize: "1em" }}
                onClick={() => setEdit(!edit)}
              >
                Edit
              </span>
            ) : (
              <div className="mt-3 ml-2">
                <span
                  class="btn btn-success mr-2"
                  onClick={() => handleUpdateData(data)}
                >
                  Update
                </span>
                <span class="btn btn-danger" onClick={() => refreshData(false)}>
                  Cancel
                </span>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default EditLawFirm;
