const awsConfig = {
  aws_project_region: "ap-southeast-2",
  aws_cognito_identity_pool_id: "ap-southeast-2:a83d79e4-e0be-451d-a1b1-e79d7901e104",
  aws_cognito_region: "ap-southeast-2",
  aws_user_pools_id: "ap-southeast-2_4cLzvaaKq",
  aws_user_pools_web_client_id: "1c0vrm4jeg9rj6t1527lbb30dt",
  oauth: {},
  aws_cognito_login_mechanisms: ["EMAIL"],
  aws_cognito_signup_attributes: ["EMAIL", "FAMILY_NAME", "GIVEN_NAME", "PHONE_NUMBER"],
  aws_cognito_mfa_configuration: "OFF",
  aws_cognito_mfa_types: ["SMS"],
  aws_cognito_password_protection_settings: { passwordPolicyMinLength: 8, passwordPolicyCharacters: [] },
};

export default awsConfig;
