import React, { useState } from "react";
import Breadcrumb from "../layout/breadcrumb";

import { useParams } from "react-router-dom";
import AppointmentDetails from "./appointmentDetails";

function AppointmentDetailsPage(props) {
  const [appId] = useState(useParams().id);

  return (
    <div className="content">
      <div className="container-fluid">
        <div className="content">
          <div className="container-fluid">
            <Breadcrumb
              title="Appointment Details"
              path={[
                { title: "Home", url: "/" },
                { title: "Appointment Details", url: "#" },
              ]}
            />
            <div className="row justify-content">
              <div className="col-md-12">
                <div class="card">
                  <div class="card-body pb-0">
                    <h4 class="mt-0 header-title mb-4">Appointment Details</h4>
                  </div>
                  <AppointmentDetails appId={appId} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AppointmentDetailsPage;
