import axios from "axios";
import { apiURL } from "../config.json";

const apiEndPoint = `${apiURL}/availability`;

export async function listAvailability(docId) {
  try {
    const response = await axios.get(`${apiEndPoint}/${docId}`);
    return response.data;
  } catch (error) {
    throw new Error("Error");
  }
}

export async function getAvailability(docId, id) {
  try {
    const response = await axios.get(`${apiEndPoint}/${docId}/${id}`);
    return response.data;
  } catch (error) {
    console.log(error);
    throw new Error("Error");
  }
}

export async function setAvailability(docId, data) {
  const response = await axios.post(`${apiEndPoint}/${docId}`, data);
  return response.data;
}

export async function putAvailability(doctorId, id, data) {
  const response = await axios.put(`${apiEndPoint}/${doctorId}/${id}`, data);
  return response.data;
}

export async function deleleAvailability(doctorId, id) {
  const response = await axios.delete(`${apiEndPoint}/${doctorId}/${id}`);
  return response.data;
}

export default {
  listAvailability,
  getAvailability,
  setAvailability,
  putAvailability,
  deleleAvailability,
};
