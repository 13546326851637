import React from "react";
import Breadcrumb from "./layout/breadcrumb";
import { Auth } from "aws-amplify";

function Dashboard(props) {
  return (
    <div className="content">
      <div className="container-fluid">
        <Breadcrumb title="Dashboard" path={[{ title: "Home", url: "/" }]} />

        <div className="row justify-content">
          <div className="col-md-12">
            <div className="mt-3">
              <p className="text"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
