import React, { useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import listPlugin from "@fullcalendar/list";
import Modal from "../layout/modal";
import AppointmentDetails from "../appointments/appointmentDetails";

import timeGridPlugin from "@fullcalendar/timegrid";

import { handleAvailabilityDetails } from "../../common/agenda";
import ManageAppointments from "../appointments/index";
import { calMinutesToHours } from "../../common/time";

const DoctorFullCalendar = ({
  docId,
  specialityDetails,
  idLocation,
  docName,
}) => {
  const [data, setData] = useState([]);
  const [dateInfo, setDateInfo] = useState();
  const [myModal, setMyModal] = useState();
  /* let data1 = [
    {
      id: "1001",
      customX: "X001",
      title: "a",
      start: "2022-03-21T09:00",
      end: "2022-03-21T10:00",
      color: "#9900ff",
    },
    {
      title: "Available time (60 min)",
      start: "2022-03-21T12:00",
      end: "2022-03-21T13:00",
      color: "#0b6ab7",
      classNames: "fc-custom-available-time",
    },
  ]; */

  const [availabilityDetails, setAvailabilityDetails] = useState();

  const handleCloseModal = () => {
    setMyModal();
  };

  Date.prototype.addDays = function (days) {
    const date = new Date(this.valueOf());
    date.setDate(date.getDate() + parseInt(days, 10));
    return date;
  };

  useEffect(() => {
    if (docId && specialityDetails && idLocation) {
      setData();
      setAvailabilityDetails();

      if (dateInfo) {
        const from = dateInfo.startStr.substring(0, 10);
        const to = dateInfo.endStr.substring(0, 10);
        handleAgenda(
          docId,
          idLocation,
          from,
          to,
          parseInt(specialityDetails.session_time, 10),
          parseInt(specialityDetails.interval_time, 10)
        );
      }
    }
  }, [docId, specialityDetails, idLocation, dateInfo]);

  const handleAgenda = async (
    doc_id,
    idLocation,
    from,
    to,
    sessionTime,
    intervalTime
  ) => {
    const data = await handleAvailabilityDetails(
      doc_id,
      idLocation,
      from,
      to,
      sessionTime,
      intervalTime
    );
    setData(data);
  };

  const refreshdata = () => {
    if (docId && specialityDetails && idLocation) {
      setData();
      setAvailabilityDetails();

      if (dateInfo) {
        const from = dateInfo.startStr.substring(0, 10);
        const to = dateInfo.endStr.substring(0, 10);
        handleAgenda(
          docId,
          idLocation,
          from,
          to,
          parseInt(specialityDetails.session_time, 10),
          parseInt(specialityDetails.interval_time, 10)
        );
      }
    }
  };

  const handleEventClick = (e) => {
    const eventDetails = e.event._def.extendedProps;
    if (eventDetails.appId) {
      setMyModal(
        <Modal
          title="Event Details"
          handleClose={handleCloseModal}
          showComponent={
            <AppointmentDetails
              appId={eventDetails.appId}
              hasBeenUpdated={refreshdata}
            />
          }
          xl={true}
        />
      );
    }
    if (eventDetails.period_type && eventDetails.period_type === "available") {
      setMyModal(
        <Modal
          title="Create New Appointment"
          handleClose={handleCloseModal}
          showComponent={
            <ManageAppointments
              onClose={handleCloseModal}
              defaultData={{
                doc: {
                  id: docId,
                  locationId: idLocation,
                  name: docName,
                  speciality: specialityDetails.speciality,
                },
                period: {
                  from: calMinutesToHours(eventDetails.startMinutes),
                  to: calMinutesToHours(eventDetails.endMinutes),
                  date: eventDetails.appointDate,
                },
              }}
            />
          }
          xl={true}
        />
      );
    }
  };

  const handleNewAppointment = () => {
    setMyModal(
      <Modal
        title="Create New Appointment"
        handleClose={handleCloseModal}
        showComponent={
          <ManageAppointments
            onClose={handleCloseModal}
            defaultData={{
              doc: {
                id: docId,
                locationId: idLocation,
                name: docName,
                speciality: specialityDetails.speciality,
              },
            }}
          />
        }
        xl={true}
      />
    );
  };

  return (
    <>
      {myModal && myModal}
      <FullCalendar
        headerToolbar={{
          start: "title", // will normally be on the left. if RTL, will be on the right
          center: "",
          end: "today prev,next", // will normally be on the right. if RTL, will be on the left
        }}
        height={450}
        eventClick={(e) => handleEventClick(e)}
        plugins={[timeGridPlugin, dayGridPlugin, listPlugin, interactionPlugin]}
        initialView="dayGridWeek"
        events={data}
        datesSet={(dateInfo) => setDateInfo(dateInfo)}
      />
      <button className="btn-success mt-2" onClick={refreshdata}>
        Refresh data
      </button>
      <button
        className="btn-primary mt-2 ml-2"
        onClick={() => handleNewAppointment()}
      >
        Create an appointment
      </button>
    </>
  );
};

export default DoctorFullCalendar;
