import React, { useState, useEffect } from "react";
import DoctorForm from "./doctorForm";
import PeriodForm from "./periodForm";
import LawFirmForm from "./lawFirmForm";
import ClientForm from "./clientForm";
import { Auth } from "aws-amplify";
import { createAppoint } from "../../services/appointments";

const CreateAppointment = ({ setId, onClose, defaultData }) => {
  const [doc, setDoc] = useState({});
  const [period, setPeriod] = useState({});
  const [lawFirm, setLawFirm] = useState({});
  const [client, setClient] = useState({});
  const [errorMessage, setErrorMessage] = useState([]);
  const [idCreated, setIdCreated] = useState();

  const validateData = () => {
    setErrorMessage([]);
    const errorData = [];
    //validate doc
    (!doc.id || !doc.speciality) && errorData.push("- Please select a doctor");
    (doc.locationId === undefined || doc.locationId === "") &&
      errorData.push("- Please select the doctor location");

    !period.date && errorData.push("- Please select a valid date");
    !period.from && errorData.push("- Please select a valid period from");
    !period.to && errorData.push("- Please select a valid period to");

    !lawFirm.lawFirm && errorData.push("- Please inform Lawfirm Name");
    !lawFirm.lawyer_name && errorData.push("- Please inform Lawyer Name");
    !lawFirm.lawyer_email && errorData.push("- Please inform Lawfirm Email");
    (!lawFirm.lawyer_phone || lawFirm.lawyer_phone.length > 13) &&
      errorData.push("- Invalid Lawfirm phone number format");

    !client.dt_birth &&
      errorData.push("- Please inform the client's Date of Birth");
    !client.client && errorData.push("- Please inform the client's Name");
    !client.email && errorData.push("- Please inform the client's Email");
    client.phone &&
      client.phone.length > 13 &&
      errorData.push("- Invalid client phone number format");
    !client.injury_type && errorData.push("- Please inform the injury type");
    if (errorData.length > 0) {
      setErrorMessage(errorData);
      setTimeout(() => {
        setErrorMessage([]);
      }, 6000);
      return false;
    }
    return true;
  };

  const submitData = async () => {
    if (!validateData()) {
      return;
    }
    try {
      const userDetails = await Auth.currentUserInfo();
      const sendUserDetails = {
        username: userDetails.username,
        email: userDetails.attributes.email,
        sub: userDetails.attributes.sub,
      };
      const result = await createAppoint({
        doc,
        period,
        lawFirm,
        client,
        userDetails: sendUserDetails,
      });
      setIdCreated(result.id);
      setDoc({});
      setPeriod({});
      setLawFirm({});
      setClient({});
      setErrorMessage({});
    } catch (error) {
      setErrorMessage([
        "Error when creating this appointment, please try again or contact administrator",
      ]);
      setTimeout(() => {
        setErrorMessage([]);
      }, 6000);
    }
  };

  useEffect(() => {
    if (defaultData) {
      if (defaultData.doc) {
        setDoc(defaultData.doc);
      }
      if (defaultData.period) {
        setPeriod(defaultData.period);
      }
    }
  }, []);

  return (
    <div className="col-12 mb-4">
      {idCreated ? (
        <div className="border rounded border-primary p-4">
          <p>The new appointment was successfully created!</p>
          <p>
            <button
              className="btn btn-success"
              onClick={() => setId(idCreated)}
            >
              Edit
            </button>{" "}
            {onClose && (
              <button className="btn btn-danger" onClick={() => onClose()}>
                Close
              </button>
            )}
          </p>
        </div>
      ) : (
        <div className="border rounded border-primary p-4">
          <DoctorForm setDoc={setDoc} doc={doc} />
          <PeriodForm setPeriod={setPeriod} period={period} />
          <hr class="mt-2" />
          <LawFirmForm setLawFirm={setLawFirm} lawFirm={lawFirm} />
          <hr class="mt-2" />
          <ClientForm setClient={setClient} client={client} />
          <hr class="mt-2" />
          <button className="btn btn-success" onClick={submitData}>
            Create
          </button>
          {errorMessage.length > 0 && (
            <div className="row">
              <div className="col mt-2">
                <ul className="ulErrorList">
                  {errorMessage.map((item, key) => (
                    <li key={key}>{item}</li>
                  ))}
                </ul>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CreateAppointment;
