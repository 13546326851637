import axios from "axios";
import { apiURL } from "../config.json";

const apiEndPoint = `${apiURL}/calendar`;

export async function listEvents(filters) {
  try {
    const response = await axios.post(apiEndPoint, { filters });
    return response.data;
  } catch (error) {
    return [];
  }
}

export async function exportXLS(filters) {
  try {
    const response = await axios.post(`${apiEndPoint}/export/xls`, { filters });
    return response.data;
  } catch (error) {
    console.log(error);
    return "error";
  }
}

export async function exportPDF(list) {
  try {
    const response = await axios.post(`${apiEndPoint}/export/pdf`, { list });
    return response.data;
  } catch (error) {
    console.log(error);
    return "error";
  }
}

export async function getFullDetails(docId, locationId, notPrivate, from, to) {
  try {
    const response = await axios.post(
      `${apiEndPoint}/fulldetails/${from}/${to}`,
      { docId, locationId, notPrivate }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return "error";
  }
}

/* export async function eventDetails(id) {
  const data = {
    date: "12/12/2021",
    from: "12:00",
    to: "12:45",
  };
  return data;
} */

export default {
  listEvents,
  exportXLS,
  exportPDF,
  getFullDetails,
};
