import React, { useState, useEffect } from "react";

const TextField = ({ label, value, maxLength, onChange }) => {
  return (
    <p>
      <strong>{label}:</strong>
      <br />
      <input
        type="text"
        value={value}
        maxLength={maxLength || 255}
        onChange={onChange}
        style={{ width: "80%" }}
      />
    </p>
  );
};

const LawFirmForm = ({ setLawFirm, lawFirm }) => {
  return (
    <div className="row">
      <div className="col">
        <p>
          <strong>Law firm and Lawyer details</strong>
        </p>
        <div className="row">
          <div className="col-12 col-md-6">
            <TextField
              label="Name"
              value={lawFirm.name}
              onChange={(e) =>
                setLawFirm({ ...lawFirm, lawFirm: e.target.value })
              }
            />
          </div>
          <div className="col-12 col-md-6">
            <TextField
              label="Reference"
              value={lawFirm.reference}
              onChange={(e) =>
                setLawFirm({ ...lawFirm, reference: e.target.value })
              }
            />
          </div>
          <div className="col-12 col-md-6">
            <TextField
              label="Lawyer"
              value={lawFirm.lawyer_name}
              onChange={(e) =>
                setLawFirm({ ...lawFirm, lawyer_name: e.target.value })
              }
            />
          </div>
          <div className="col-12 col-md-6">
            <TextField
              label="Phone"
              value={lawFirm.lawyer_phone}
              onChange={(e) =>
                setLawFirm({ ...lawFirm, lawyer_phone: e.target.value })
              }
              maxLength={13}
            />
          </div>
          <div className="col-12 col-md-6">
            <TextField
              label="Email"
              value={lawFirm.lawyer_email}
              onChange={(e) =>
                setLawFirm({ ...lawFirm, lawyer_email: e.target.value })
              }
            />
          </div>
          <div className="col-12 col-md-6">
            <TextField
              label="Address"
              value={lawFirm.lawyer_address}
              onChange={(e) =>
                setLawFirm({ ...lawFirm, lawyer_address: e.target.value })
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LawFirmForm;
