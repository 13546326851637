import React, { useState } from "react";

import { updateAppointStatus } from "../../services/appointments";
import { Auth } from "aws-amplify";

function EditStatus({ appId, status, hasBeenUpdated }) {
  const [edit, setEdit] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(status);
  const [newStatus, setNewStatus] = useState(status);
  const [errMsg, setErrMsg] = useState(false);

  const handleUpdate = async () => {
    try {
      const userDetails = await Auth.currentUserInfo();
      const userdData = {
        username: userDetails.username,
        email: userDetails.attributes.email,
        sub: userDetails.attributes.sub,
      };

      await updateAppointStatus(appId, newStatus, userdData);
      setCurrentStatus(newStatus);
      hasBeenUpdated && hasBeenUpdated();
    } catch (error) {
      console.log({ error });
      setErrMsg(true);
      setTimeout(() => {
        setErrMsg(false);
      }, 4000);
    } finally {
      setEdit(false);
    }
  };

  const handleCancel = () => {
    setNewStatus(currentStatus);
    setEdit(false);
  };

  return (
    <div className="col-12 p-0">
      {!edit ? (
        <div className="col-6">
          <p>
            <strong>Status:</strong> {currentStatus.toUpperCase()}{" "}
            <span
              className="badge badge-primary ml-2 pointer"
              style={{ fontSize: "1em" }}
              onClick={() => setEdit(true)}
            >
              Edit
            </span>
          </p>
        </div>
      ) : (
        <div className="col-6">
          <p>
            <strong>Status:</strong>
            <select
              class="form-control"
              id="status"
              value={newStatus}
              onChange={(e) => setNewStatus(e.target.value)}
            >
              <option value="pending">Pending</option>
              <option value="confirmed">Confirmed</option>
              <option value="cancelled">Cancelled</option>
            </select>
          </p>
          <span className="btn btn-success mr-2" onClick={handleUpdate}>
            Update
          </span>
          <span className="btn btn-danger" onClick={handleCancel}>
            Cancel
          </span>
        </div>
      )}

      {errMsg && (
        <div class="alert alert-warning" role="alert">
          Something went wrong. Please try again.
        </div>
      )}
    </div>
  );
}

export default EditStatus;
