import React, { useState, useEffect } from "react";
import { listDate, deleleDate } from "../../../services/newAvailableDates";

import Modal from "../../layout/modal";
import EditNewAvailability from "./editNewAvailability";
import format from "date-format";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ListNewAvailability({ docId }) {
  const [data, setData] = useState([]);
  const [sendError, setSendError] = useState(null);
  //Modal
  const [showModal, setShowModal] = useState();
  const fetchData = async () => {
    try {
      const data = await listDate(docId);
      setData(data);
    } catch (error) {
      setSendError(
        "Error when listing your data. Please try again or contact support."
      );
    }
  };

  const handleCloseModal = () => {
    setShowModal();
  };
  const handleShoModal = (itemId) => {
    console.log({ itemId });
    setShowModal(
      <Modal
        handleClose={handleCloseModal}
        title="Manage Irregular Available Dates"
        showComponent={
          <EditNewAvailability
            docId={docId}
            id={itemId}
            forceDataRefresh={fetchData}
          />
        }
      />
    );
  };

  const deleteNA = async (id) => {
    try {
      await deleleDate(id);
      toast.success("The selected item has been removed!");
      fetchData();
    } catch (error) {
      toast.warn(`Something went wrong.`);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="row justify-content">
      {showModal}
      <div className="col-md-12">
        <div class="card">
          <div class="card-body">
            <div className="row">
              <div className="col-12 col-md-8">
                <h4 class="mt-0 header-title mb-4">
                  Irregular Available Dates
                </h4>
              </div>
              <div className="col-12 col-md-4 text-right">
                <bottton
                  className="btn btn-primary"
                  onClick={() => handleShoModal(null)}
                >
                  Add new
                </bottton>
              </div>
            </div>

            <div class="table-responsive">
              {sendError ? (
                <div>{sendError}</div>
              ) : (
                <table class="table table-striped mb-0">
                  <thead>
                    <tr>
                      <th>Location</th>
                      <th>Date</th>
                      <th>From</th>
                      <th>To</th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>

                  {data && data.length === 0 ? (
                    <tr>
                      <td colSpan="6">
                        Click on 'Add new' to create a new extra available date
                      </td>
                    </tr>
                  ) : (
                    <tbody>
                      {data.map((item, index) => (
                        <tr key={index}>
                          <td>
                            <pre>{item.address}</pre>
                            <pre>{item.location}</pre>
                          </td>
                          <td>{format("dd/MM/yyyy", new Date(item.date))}</td>
                          <td>{item.from}</td>
                          <td>{item.to}</td>
                          <td>
                            <button
                              onClick={() => handleShoModal(item.id)}
                              className="btn btn-outline-info"
                            >
                              Edit
                            </button>
                          </td>
                          <td>
                            <button
                              onClick={() => deleteNA(item.id)}
                              className="btn btn-outline-danger"
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  )}
                </table>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ListNewAvailability;
