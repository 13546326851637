const calHoursToMinutes = (hStr) => {
    let hArr = hStr.split(":");
    let min = parseInt(hArr[0], 10) * 60 + parseInt(hArr[1], 10);
    return min;
  };

  const calMinutesToHours = (mInt) => {
    const h = Math.floor(mInt / 60);
    const m = mInt % 60;

    let hStr = h.toString();
    if (h < 10) hStr = "0" + hStr;

    let mStr = m.toString();
    if (m < 10) mStr = "0" + mStr;

    return hStr + ":" + mStr;
  };

  exports.calHoursToMinutes = calHoursToMinutes;
  exports.calMinutesToHours = calMinutesToHours;