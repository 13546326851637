import React, { useState, useEffect } from "react";
import {
  getDate,
  setNewDate,
  putDate,
} from "../../../services/newAvailableDates";

import { listLocations } from "../../../services/locations";

import Joi from "joi-browser";

function EditNewAvailability({ docId, id, forceDataRefresh }) {
  const [data, setData] = useState({ recurrent: 0, repeat: 1 });
  const [naId, setNAId] = useState(id);
  const [alertMsg, setAlertMessage] = useState({});
  const [locations, setLocations] = useState([]);

  const [errors, setErrors] = useState();

  const [schema] = useState({
    id_location: Joi.number().required().label("Location"),
    date: Joi.string().required().min(10).max(10).label("Date"),
    from: Joi.string().required().min(5).max(5).label("Period From"),
    to: Joi.string().required().min(5).max(5).label("Period From"),
    recurrent: Joi.number(),
    repeat: Joi.number(),
  });

  const validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(data, schema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  const handleUpdate = async () => {
    try {
      await putDate(naId, data);
      forceDataRefresh();
      setAlertMessage({
        type: "success",
        message: "Your data has been updated!",
      });
    } catch (error) {
      setAlertMessage({ type: "warning", message: "Something went wrong!" });
    } finally {
      setTimeout(() => {
        setAlertMessage({});
      }, 7000);
    }
  };

  const handleAdd = async () => {
    try {
      await setNewDate(docId, data);
      //newId = parseInt(newId, 10);
      //setNAId(newId);
      forceDataRefresh();
      setAlertMessage({
        type: "success",
        message: "Period created successfully!",
      });
      setData({ recurrent: 0, repeat: 1 });
    } catch (error) {
      setAlertMessage({ type: "warning", message: "Something went wrong!" });
    } finally {
      setTimeout(() => {
        setAlertMessage({});
      }, 7000);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const errors = validate();
    setErrors(errors);
    if (errors) return;

    if (naId !== null && !isNaN(naId)) {
      handleUpdate();
    } else {
      handleAdd();
    }
  };

  const fetchData = async (id) => {
    try {
      const data = await getDate(id);
      setData({
        date: data.date,
        from: data.from,
        to: data.to,
        id_location: data.id_location,
      });
    } catch (error) {}
  };

  const fetchLocations = async () => {
    try {
      const data = await listLocations(docId);
      setLocations(data);
    } catch (error) {
      setAlertMessage({ type: "warning", message: "Something went wrong!" });
      setTimeout(() => {
        setAlertMessage({});
      }, 7000);
    }
  };

  useEffect(() => {
    if (id && id !== null) {
      fetchData(id);
      setData({});
    }
    fetchLocations();
  }, []);

  const handleRecurrent = (value) => {
    setData({
      ...data,
      recurrent: parseInt(value),
      repeat: parseInt(value) === 0 ? 1 : data.repeat,
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      {locations && locations.length === 0 ? (
        <div>You need at least one location.</div>
      ) : (
        <div className="row">
          <div className="col-12">
            <div class="form-group">
              <label>Location</label>

              <select
                class="form-control"
                value={data.id_location || ""}
                onChange={(e) =>
                  setData({ ...data, id_location: e.target.value })
                }
                id="id_location"
              >
                <option value=""></option>
                {locations.map((item, index) => (
                  <option value={item.id} key={index}>
                    {item.city} - {item.address.substring(0, 30)}
                  </option>
                ))}
              </select>
              {errors && errors.id_location && (
                <div className="alert alert-danger py-1 mt-2">
                  {errors.id_location}
                </div>
              )}
            </div>

            <div class="form-group">
              <label>Date</label>
              <input
                type="date"
                placeholder=""
                class="form-control"
                value={data.date || ""}
                id="date"
                onChange={(e) => setData({ ...data, date: e.target.value })}
              />
              {errors && errors.date && (
                <div className="alert alert-danger py-1 mt-2">
                  {errors.date}
                </div>
              )}
            </div>

            <div className="row">
              <div className="col-6">
                <div class="form-group">
                  <label>From</label>
                  <input
                    type="text"
                    placeholder="00:00"
                    class="form-control"
                    value={data.from || ""}
                    id="from"
                    onChange={(e) => setData({ ...data, from: e.target.value })}
                  />
                  {errors && errors.from && (
                    <div className="alert alert-danger py-1 mt-2">
                      {errors.from}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-6">
                <div class="form-group">
                  <label>To</label>
                  <input
                    type="text"
                    placeholder="00:00"
                    class="form-control"
                    value={data.to || ""}
                    id="to"
                    onChange={(e) => setData({ ...data, to: e.target.value })}
                  />
                  {errors && errors.to && (
                    <div className="alert alert-danger py-1 mt-2">
                      {errors.to}
                    </div>
                  )}
                </div>
              </div>
            </div>

            {!naId && (
              <div className="row">
                <div className="col-6">
                  <div class="form-group">
                    <label>Recurrent</label>
                    <select
                      class="form-control"
                      value={data.recurrent || 0}
                      onChange={(e) => handleRecurrent(e.target.value)}
                      id="id_location"
                    >
                      <option value="0">Not recurrent</option>
                      <option value="7">Weekly</option>
                      <option value="14">Fortnightly</option>
                      <option value="30">Monthly</option>
                    </select>
                  </div>
                </div>
                <div className="col-6">
                  {data.recurrent > 0 && (
                    <div class="form-group">
                      <label>Repeat</label>
                      <input
                        type="number"
                        placeholder="0"
                        class="form-control"
                        value={data.repeat || 0}
                        id="repeat"
                        onChange={(e) =>
                          setData({ ...data, repeat: parseInt(e.target.value) })
                        }
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className="col-12 text-left text-md-right">
            <button className="btn btn-primary w-50">Save</button>
          </div>
          {alertMsg.type && (
            <div className="col-12 text-left text-md-left mt-3">
              <div class={`alert alert-${alertMsg.type}`} role="alert">
                {alertMsg.message}
              </div>
            </div>
          )}
        </div>
      )}
    </form>
  );
}

export default EditNewAvailability;
