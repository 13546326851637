import axios from "axios";
import { apiURL } from "../config.json";

const apiEndPoint = `${apiURL}`;

export async function getLocation() {
  const response = await axios.get(
    apiEndPoint + "/locations/web/listedlocations/"
  );
  return response.data;
}

export async function getDocDetailsById(id) {
  try {
    const response = await axios.post(apiEndPoint + "/docs/web/search", {
      searchBy: "byId",
      id,
    });
    return response.data;
  } catch (error) {
    console.log(error, id);
    return [];
  }
}

export default { getLocation, getDocDetailsById };
