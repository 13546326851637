import React, { useEffect } from "react";
import _ from "lodash";

const EditDoctorForm = (props) => {
  const {
    handleSubmit,
    setData,
    data,
    docSpecsList,
    setDocSpecsList,
    specs,
    handleClose,
  } = props;

  const handleInputChange = (id) => {
    const specIndex = _.findIndex(docSpecsList, function (o) {
      return o.id === id;
    });
    let list = [...docSpecsList];
    if (specIndex === -1) {
      list.push({ id });
      setDocSpecsList(list);
    } else {
      list.splice(specIndex, 1);
      setDocSpecsList(list);
    }
  };

  const handleByVideo = () => {
    if (data.by_video && data.by_video === 1) {
      setData({ ...data, by_video: 0 });
    } else {
      setData({ ...data, by_video: 1 });
    }
  };

  const handleHomeVisit = () => {
    if (data.home_visit && data.home_visit === 1) {
      setData({ ...data, home_visit: 0 });
    } else {
      setData({ ...data, home_visit: 1 });
    }
  };

  const handleCallToBook = () => {
    if (data.call_to_book && data.call_to_book === 1) {
      setData({ ...data, call_to_book: 0 });
    } else {
      setData({ ...data, call_to_book: 1 });
    }
  };

  const handleSessionTime = (time_type, itemId, value) => {
    let list = [...docSpecsList];
    const specIndex = _.findIndex(list, function (o) {
      return o.id === itemId;
    });
    list[specIndex][time_type] = value;
    setDocSpecsList(list);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-md-6">
          <div class="form-group">
            <label>Title</label>
            <input
              type="text"
              placeholder=""
              class="form-control"
              value={data.title || ""}
              id="title"
              onChange={(e) => setData({ ...data, title: e.target.value })}
              maxLength="15"
            />
            <span class="font-13 text-muted"></span>
          </div>
          <div class="form-group">
            <label>Name</label>
            <input
              type="text"
              placeholder=""
              class="form-control"
              value={data.name || ""}
              id="name"
              maxLength="100"
              onChange={(e) => setData({ ...data, name: e.target.value })}
            />
            <span class="font-13 text-muted"></span>
          </div>
          <div class="form-group">
            <label>Email</label>
            <input
              type="text"
              placeholder=""
              class="form-control"
              value={data.email || ""}
              id="email"
              maxLength="200"
              onChange={(e) => setData({ ...data, email: e.target.value })}
            />
            <span class="font-13 text-muted"></span>
          </div>
          <div class="form-group">
            <label>Phone Number</label>
            <input
              type="number"
              placeholder=""
              class="form-control"
              value={data.phone || ""}
              id="phone"
              maxLength="15"
              onChange={(e) => setData({ ...data, phone: e.target.value })}
            />
            <span class="font-13 text-muted"></span>
          </div>
          <div class="form-group">
            <div class="custom-control custom-checkbox" onClick={handleByVideo}>
              <input
                type="checkbox"
                class="custom-control-input"
                checked={data.by_video && data.by_video === 1 ? true : false}
              />
              <label class="custom-control-label" for="customCheck1">
                Request IME by Video Link Available
              </label>
            </div>
          </div>
          <div class="form-group">
            <div
              class="custom-control custom-checkbox"
              onClick={handleHomeVisit}
            >
              <input
                type="checkbox"
                class="custom-control-input"
                checked={
                  data.home_visit && data.home_visit === 1 ? true : false
                }
              />
              <label class="custom-control-label" for="customCheck1">
                Home Visit Available
              </label>
            </div>
          </div>
          <div class="form-group">
            <div
              class="custom-control custom-checkbox"
              onClick={handleCallToBook}
            >
              <input
                type="checkbox"
                class="custom-control-input"
                checked={
                  data.call_to_book && data.call_to_book === 1 ? true : false
                }
              />
              <label class="custom-control-label" for="customCheck1">
                Call to Book
              </label>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div class="form-group">
            <label>Specialities</label>
            {specs &&
              Array.isArray(specs) &&
              specs.map((item, index) => (
                <>
                  <div
                    class="custom-control custom-checkbox"
                    key={index}
                    onClick={() => handleInputChange(item.id)}
                  >
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      checked={
                        Array.isArray(docSpecsList) &&
                        _.findIndex(docSpecsList, function (o) {
                          return o.id === item.id;
                        }) !== -1
                          ? true
                          : false
                      }
                      id={index}
                    />
                    <label
                      class="custom-control-label d-block"
                      for="customCheck1"
                    >
                      {item.speciality}
                    </label>
                  </div>
                  <div>
                    {Array.isArray(docSpecsList) &&
                      _.findIndex(docSpecsList, function (o) {
                        return o.id === item.id;
                      }) !== -1 && (
                        <>
                          <p className="my-2">
                            Session Time:{" "}
                            <input
                              type="number"
                              name={`session_time_${item.id}`}
                              value={
                                docSpecsList[
                                  _.findIndex(docSpecsList, function (o) {
                                    return o.id === item.id;
                                  })
                                ].session_time || ""
                              }
                              onChange={(e) =>
                                handleSessionTime(
                                  "session_time",
                                  item.id,
                                  e.target.value
                                )
                              }
                            />
                          </p>
                          <p>
                            Interval Time:{" "}
                            <input
                              type="number"
                              name={`interval_time_${item.id}`}
                              value={
                                docSpecsList[
                                  _.findIndex(docSpecsList, function (o) {
                                    return o.id === item.id;
                                  })
                                ].interval_time || ""
                              }
                              onChange={(e) =>
                                handleSessionTime(
                                  "interval_time",
                                  item.id,
                                  e.target.value
                                )
                              }
                            />
                          </p>
                        </>
                      )}
                  </div>
                </>
              ))}
          </div>
          <div class="form-group d-none">
            <label>Time Session (minutes)</label>
            <input
              type="number"
              placeholder=""
              class="form-control"
              value={data.session_time || "45"}
              id="session_time"
              maxLength="3"
              onChange={(e) =>
                setData({
                  ...data,
                  session_time: e.target.value,
                })
              }
            />
            <span class="font-13 text-muted"></span>
          </div>
          <div class="form-group d-none">
            <label>Interval between session (minutes)</label>
            <input
              type="number"
              placeholder=""
              class="form-control"
              value={data.interval_time || "15"}
              id="interval_time"
              maxLength="3"
              onChange={(e) =>
                setData({
                  ...data,
                  interval_time: e.target.value,
                })
              }
            />
            <span class="font-13 text-muted"></span>
          </div>
          <div class="form-group">
            <label>Status</label>
            <select
              class="form-control"
              value={data.status || 1}
              onChange={(e) => setData({ ...data, status: e.target.value })}
              id="status"
            >
              <option value="1">Available</option>
              <option value="0">Not Available</option>
            </select>
          </div>
        </div>

        <div className="col-12 text-left text-md-right">
          <button className="btn btn-primary w-50">Save</button>
        </div>
        <div className="col-12 text-left text-md-right mt-4">
          <span
            style={{ marginRight: "10px" }}
            className="pointer"
            onClick={() => handleClose(false)}
          >
            Close
          </span>
        </div>
      </div>
    </form>
  );
};

export default EditDoctorForm;
