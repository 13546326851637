import React, { useState, useEffect } from "react";
import {
  getCities,
  getLocation,
  setLocation,
  putLocation,
} from "../../../services/locations";

function EditLocation({ docId, id, forceDataRefresh }) {
  const [data, setData] = useState({});
  const [locationId, setLocationId] = useState(id);
  const [alertMsg, setAlertMessage] = useState({});

  const [cities, setCities] = useState([]);

  const handleUpdateLocation = async () => {
    try {
      await putLocation(docId, locationId, data);
      forceDataRefresh();
      setAlertMessage({
        type: "success",
        message: "Your data has been updated!",
      });
    } catch (error) {
      setAlertMessage({ type: "warning", message: "Something went wrong!" });
    } finally {
      setTimeout(() => {
        setAlertMessage({});
      }, 7000);
    }
  };

  const handleAddLocation = async () => {
    try {
      const newlocation = await setLocation(docId, data);
      setLocationId(newlocation);
      forceDataRefresh();
      setAlertMessage({
        type: "success",
        message: "The new location has been created!",
      });
    } catch (error) {
      setAlertMessage({ type: "warning", message: "Something went wrong!" });
    } finally {
      setTimeout(() => {
        setAlertMessage({});
      }, 7000);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (locationId !== null && !isNaN(locationId)) {
      handleUpdateLocation();
    } else {
      handleAddLocation();
    }
  };

  const fetchCities = async (state) => {
    try {
      const cities = await getCities(state);
      setCities(cities);
    } catch (error) {
      setCities([]);
    }
  };

  const handleState = (e) => {
    const state = e.target.value;
    setData({ ...data, state, city: "" });
    if (state !== "") {
      fetchCities(state);
    }
  };

  //getLocation(docId, id)
  const fetchData = async (docId, id) => {
    const location = await getLocation(docId, id);
    location.status = location.status.toString();
    setData(location);

    if (location.state) {
      fetchCities(location.state);
    }
  };

  useEffect(() => {
    if (id && id !== null) {
      fetchData(docId, id);
    }
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-md-6">
          <div class="form-group">
            <label>State</label>
            <select
              class="form-control"
              value={data.state || ""}
              onChange={(e) => handleState(e)}
              id="state"
            >
              <option value="">Select</option>
              <option value="ACT">Australian Capital Territory</option>
              <option value="NSW">New South Wales</option>
              <option value="NT">Northern Territory</option>
              <option value="QLD">Queensland</option>
              <option value="SA">South Australia</option>
              <option value="TA">Tasmania</option>
              <option value="VIC">Victoria</option>
              <option value="WA">Western Australia</option>
            </select>
            <span class="font-13 text-muted"></span>
          </div>
          <div class="form-group">
            <label>City</label>

            <select
              class="form-control"
              value={data.city || ""}
              onChange={(e) => setData({ ...data, city: e.target.value })}
              id="city"
            >
              <option value=""></option>
              {cities.map((item, index) => (
                <option value={item.city} key={index}>
                  {item.city}
                </option>
              ))}
            </select>
            <span class="font-13 text-muted"></span>
          </div>
          <div class="form-group">
            <label>Post Code</label>
            <input
              type="text"
              placeholder=""
              class="form-control"
              value={data.postcode || ""}
              id="postcode"
              onChange={(e) => setData({ ...data, postcode: e.target.value })}
              maxLength="4"
            />
            <span class="font-13 text-muted"></span>
          </div>
        </div>
        <div className="col-md-6">
          <div class="form-group">
            <label>Address</label>
            <textarea
              placeholder=""
              class="form-control"
              style={{ height: "115px" }}
              value={data.address || ""}
              id="address"
              onChange={(e) =>
                setData({
                  ...data,
                  address: e.target.value,
                })
              }
            />
            <span class="font-13 text-muted"></span>
          </div>
          <div class="form-group">
            <label>Status</label>
            <select
              class="form-control"
              value={data.status && parseInt(data.status) === 0 ? 0 : 1}
              onChange={(e) => setData({ ...data, status: e.target.value })}
              id="status"
            >
              <option value={"1"}>Available</option>
              <option value={"0"}>Not Available</option>
            </select>
          </div>
        </div>
        <div className="col-12 text-left text-md-right">
          <button className="btn btn-primary w-50">Save</button>
        </div>
        {alertMsg.type && (
          <div className="col-12 text-left text-md-left mt-3">
            <div class={`alert alert-${alertMsg.type}`} role="alert">
              {alertMsg.message}
            </div>
          </div>
        )}
      </div>
    </form>
  );
}

export default EditLocation;
