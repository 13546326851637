import axios from "axios";
import { apiURL } from "../config.json";

const apiEndPoint = `${apiURL}/user`;

export async function listUsers() {
  try {
    const response = await axios.get(apiEndPoint + "/list");
    return response.data;
  } catch (error) {
    return [];
  }
}

export async function createUser(data) {
  try {
    const response = await axios.post(apiEndPoint + "/create", data);
    return response.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    }
  }
}
/* DATA
{
	"username": "raflps",
	"phone_number": "+61451012998", 
	"given_name": "Raf", 
	"family_name": "Lopes", 
	"email": "rafaellopes.au@gmail.com" 
    "temporaryPassword": "111111111111"
}
*/

export async function deleteUser(userName) {
  try {
    const response = await axios.delete(apiEndPoint + "/delete/" + userName);
    return response.data;
  } catch (error) {
    if (error.response) {
      return error.response.data.message;
    }
  }
}

export async function updateUser(data) {
  try {
    const response = await axios.put(apiEndPoint + "/update/", data);
    return response.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    }
  }
}
/*
export async function updateMFA(data) {
  try {
    const response = await axios.put(apiEndPoint + "/mfa/", data);
    return response.data;
  } catch (error) {
    if (error.response) {
      return error.response.data.message;
    }
  }
}
*/
