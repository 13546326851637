import React from "react";

const PeriodForm = ({ period, setPeriod }) => {
  return (
    <div className="row">
      <div className="col">
        <p className="mt-2 mb-2">
          Data:{" "}
          <input
            type="date"
            value={period.date}
            onChange={(e) => setPeriod({ ...period, date: e.target.value })}
          />
        </p>
        <p className="mt-2 mb-2">
          From:{" "}
          <input
            type="text"
            value={period.from}
            maxLength="5"
            style={{ maxWidth: "60px" }}
            onChange={(e) => setPeriod({ ...period, from: e.target.value })}
          />{" "}
          To:{" "}
          <input
            type="text"
            value={period.to}
            maxLength="5"
            style={{ maxWidth: "60px" }}
            onChange={(e) => setPeriod({ ...period, to: e.target.value })}
          />
        </p>
      </div>
    </div>
  );
};

export default PeriodForm;
