import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Breadcrumb from "../layout/breadcrumb";
import { listTemplates, deleleTemplate } from "../../services/emailTemplate";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ListOfTemplates(props) {
  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      const results = await listTemplates("active,draft");
      if (Array.isArray(results)) {
        setData(results);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await deleleTemplate(id);
      toast.success("The selected template has been removed!");
      fetchData();
    } catch (error) {
      toast.warn(`Something went wrong. Template hasn't been removed.`);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <>
      <ToastContainer />
      <Breadcrumb
        title="Email Template"
        path={[
          { title: "Home", url: "/" },
          { title: "Email Template", url: "/email-template" },
        ]}
      />
      <div className="row justify-content">
        <div className="col-md-12">
          <div class="card">
            <div class="card-body">
              <div className="row">
                <div className="col-12 col-md-8">
                  <h4 class="mt-0 header-title mb-4">List of Templates</h4>
                </div>
                <div className="col-12 col-md-4 text-right">
                  <Link to={`/email-template`} className="btn btn-primary">
                    Add new template
                  </Link>
                </div>
              </div>

              <div class="table-responsive">
                <table class="table table-striped mb-0">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Status</th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.length > 0 ? (
                      <>
                        {data.map((item, index) => (
                          <tr key={index}>
                            <td>{item.title}</td>
                            <td>{item.status.toUpperCase()}</td>
                            <td>
                              <Link
                                to={`/email-template/${item.id}`}
                                className="btn btn-outline-info"
                              >
                                Edit
                              </Link>
                            </td>
                            <td>
                              <button
                                className="btn btn-outline-danger"
                                onClick={() => handleDelete(item.id)}
                              >
                                Delete
                              </button>
                            </td>
                          </tr>
                        ))}
                      </>
                    ) : (
                      <tr>
                        <th scope="row" colspan="7">
                          Start now adding your first template!
                        </th>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ListOfTemplates;
