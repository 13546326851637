import React, { useEffect, useState } from "react";
import Breadcrumb from "../layout/breadcrumb";
import docs, {
  fetchDoc,
  fetchRelDocSpecs,
  addDoctor,
  updateDoctor,
} from "../../services/docs";
import { listSpecs } from "../../services/specs";
import { useParams } from "react-router-dom";
import _ from "lodash";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ListLocations from "./locations/listLocations";
import ListAvailability from "./availability/listAvailability";
import ListNA from "./notavailable/listNotAvailable";
import ListNewAvailability from "./newavailability/listNewAvailability";
import EditDoctorForm from "./editDoctorForm";
import DoctorSummaryData from "./doctorSummaryData";
import DoctorFullCalendar from "./doctorFullCalendar";
import { listLocations } from "../../services/locations";

function EditDoctor(props) {
  const [isEditing, setIsEditing] = useState(false);
  const [data, setData] = useState({});
  const [docSpecsList, setDocSpecsList] = useState([]);
  const [specs, setSpecs] = useState([]);
  const [notFoundError, setNotFoundError] = useState();
  const [docId, setDocId] = useState(useParams().id);
  const [locationsData, setLocationsData] = useState([]);
  const [agendaSelectedSpec, setAgendaSelectedSpec] = useState(0);
  const [agendaSelectedLoc, setAgendaSelectedLoc] = useState(0);

  const fetchData = async () => {
    try {
      const resp = await fetchDoc(docId);
      if (!resp) {
        setNotFoundError("Doctor not found...");
      }
      resp.status = resp.status.toString();
      setData(resp);
    } catch (error) {
      setNotFoundError("Doctor not found...");
    }
  };

  const fetchDataLocations = async () => {
    try {
      const location = await listLocations(docId);
      setLocationsData(location);
    } catch (error) {
      console.log("Error when listing location. ");
    }
  };

  const handleClose = () => {
    fetchData();
    setIsEditing(false);
  };

  const fetchDataDocSpecs = async () => {
    const resp = await fetchRelDocSpecs(docId);
    if (!resp) {
      setNotFoundError("Doctor not found...");
    }
    setDocSpecsList(resp);
  };

  useEffect(() => {
    if (!isNaN(docId) && docId !== 0) {
      fetchData();
      fetchDataDocSpecs();
      fetchDataLocations();
    }
  }, [docId]);

  const fetchSpecs = async () => {
    const data = await listSpecs();
    setSpecs(data);
  };

  const checkDoctorId = () => {};

  useEffect(() => {
    fetchSpecs();
    checkDoctorId();
  }, []);

  const handleAddDoctor = async () => {
    try {
      const result = await addDoctor({ doctor: data, specsList: docSpecsList });
      setDocId(result.id);
      toast.success("Doctor has been successfully created!");
    } catch (error) {
      toast.warn(`Something went wrong. Your data hasn't been registered.`);
    }
  };

  const handleUpdateDoctor = async () => {
    try {
      const result = await updateDoctor(
        { doctor: data, specsList: docSpecsList },
        docId
      );
      toast.success("Doctor has been updated!");
    } catch (error) {
      toast.warn(`Something went wrong. Your data hasn't been updated.`);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (docId && docId !== 0) {
      handleUpdateDoctor();
    } else {
      handleAddDoctor();
    }
    setIsEditing(false);
  };

  if (notFoundError) {
    return (
      <div className="content">
        <div className="container-fluid">
          <div>Something went wrong! {notFoundError}</div>
        </div>
      </div>
    );
  }
  return (
    <div className="content">
      <div className="container-fluid">
        <div className="content">
          <div className="container-fluid">
            <ToastContainer />
            <Breadcrumb
              title="Manage Doctor"
              path={[
                { title: "Home", url: "/" },
                { title: "Doctors", url: "/doctors" },
              ]}
            />
            <div className="row justify-content">
              <div className="col-md-12">
                <div class="card">
                  <div class="card-body">
                    <h4 class="mt-0 header-title mb-4">Doctor Details</h4>
                    {isEditing || !docId ? (
                      <EditDoctorForm
                        handleSubmit={handleSubmit}
                        setData={setData}
                        data={data}
                        docSpecsList={docSpecsList}
                        setDocSpecsList={setDocSpecsList}
                        specs={specs}
                        handleClose={handleClose}
                      />
                    ) : (
                      <DoctorSummaryData
                        data={data}
                        setIsEditing={setIsEditing}
                        docSpecsList={docSpecsList}
                        specs={specs}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>

            {!isNaN(docId) &&
              docSpecsList.length > 0 &&
              locationsData.length > 0 && (
                <div className="row justify-content">
                  <div className="col-md-12">
                    <div class="card">
                      <div class="card-body">
                        <h4 class="mt-0 header-title mb-4">Doctor Agenda</h4>

                        <p>
                          Specialities:{" "}
                          <select
                            value={agendaSelectedSpec}
                            onChange={(e) =>
                              setAgendaSelectedSpec(e.target.value)
                            }
                          >
                            {docSpecsList.map((item, index) => (
                              <option key={index} value={index}>
                                {
                                  specs.filter((e) => item.id === e.id)[0]
                                    ?.speciality
                                }
                              </option>
                            ))}
                          </select>
                        </p>

                        <p>
                          Locations:{" "}
                          <select
                            value={agendaSelectedLoc}
                            onChange={(e) =>
                              setAgendaSelectedLoc(e.target.value)
                            }
                            style={{ maxWidth: "300px" }}
                          >
                            {locationsData.map((item, index) => (
                              <option key={index} value={index}>
                                {`${item.state} - ${
                                  item.city
                                } - ${item.address.substring(0, 30)}...`}
                              </option>
                            ))}
                          </select>
                        </p>

                        <DoctorFullCalendar
                          docId={docId}
                          docName={data.title + " " + data.name}
                          specialityDetails={docSpecsList[agendaSelectedSpec]}
                          idLocation={locationsData[agendaSelectedLoc].id}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}

            {!isNaN(docId) && <ListLocations docId={docId} />}

            {!isNaN(docId) && <ListAvailability docId={docId} />}

            {!isNaN(docId) && <ListNA docId={docId} />}

            {!isNaN(docId) && <ListNewAvailability docId={docId} />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditDoctor;
