import React, { useState, useEffect } from "react";

import { updateAppointDateTime } from "../../services/appointments";
import { calMinutesToHours, calHoursToMinutes } from "../../common/time";
import format from "date-format";

function EditDateTime({ appId, date, period_from, period_to, hasBeenUpdated }) {
  const [edit, setEdit] = useState(false);
  const [currentDate, setCurrentDate] = useState(date);
  const [currentPeriodFrom, setCurrentPeriodFrom] = useState(
    calMinutesToHours(period_from)
  );
  const [currentPeriodTo, setCurrentPeriodTo] = useState(
    calMinutesToHours(period_to)
  );

  const [newDate, setNewDate] = useState(date);
  const [newPeriodFrom, setNewPeriodFrom] = useState(
    calMinutesToHours(period_from)
  );
  const [newPeriodTo, setNewPeriodTo] = useState(calMinutesToHours(period_to));

  const [errMsg, setErrMsg] = useState(false);

  const handleUpdate = async () => {
    try {
      const formatedFrom = calHoursToMinutes(newPeriodFrom);
      const formatedTo = calHoursToMinutes(newPeriodTo);
      await updateAppointDateTime(appId, {
        date: newDate,
        period_from: formatedFrom,
        period_to: formatedTo,
      });
      setCurrentDate(newDate);
      setCurrentPeriodFrom(newPeriodFrom);
      setCurrentPeriodTo(newPeriodTo);
      hasBeenUpdated && hasBeenUpdated();
    } catch (error) {
      setErrMsg(true);
      setTimeout(() => {
        setErrMsg(false);
      }, 4000);
    } finally {
      setEdit(false);
    }
  };

  const handleCancel = () => {
    setNewDate(currentDate);
    setNewPeriodFrom(currentPeriodFrom);
    setNewPeriodTo(currentPeriodTo);

    setEdit(false);
  };

  return (
    <div className="col-12 p-0">
      {!edit ? (
        <p className="mt-2">
          <strong>Date:</strong> {format("dd/MM/yyyy", new Date(currentDate))}{" "}
          at <strong>Time:</strong> {currentPeriodFrom} - {currentPeriodTo}
          <span
            className="badge badge-primary ml-2 pointer"
            style={{ fontSize: "1em" }}
            onClick={() => setEdit(true)}
          >
            Edit
          </span>
        </p>
      ) : (
        <>
          <p className="mt-2">
            <strong>Date:</strong>{" "}
            <input
              type="date"
              id="date"
              value={newDate}
              onChange={(e) => setNewDate(e.target.value)}
            />{" "}
            at <strong>Time:</strong>{" "}
            <input
              type="text"
              maxLength="5"
              value={newPeriodFrom}
              onChange={(e) => setNewPeriodFrom(e.target.value)}
            />{" "}
            -{" "}
            <input
              type="text"
              maxLength="5"
              value={newPeriodTo}
              onChange={(e) => setNewPeriodTo(e.target.value)}
            />
          </p>

          <span className="btn btn-success mr-2" onClick={handleUpdate}>
            Update
          </span>
          <span className="btn btn-danger" onClick={handleCancel}>
            Cancel
          </span>
        </>
      )}

      {errMsg && (
        <div class="alert alert-warning" role="alert">
          Something went wrong. Please try again.
        </div>
      )}
    </div>
  );
}

export default EditDateTime;
