import React, { useState, useEffect } from "react";
import {
  listAvailability,
  deleleAvailability,
} from "../../../services/availability";
import Modal from "../../layout/modal";
import EditAvailability from "./editAvailability";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ListAvailability({ docId }) {
  const [data, setData] = useState([]);
  const [sendError, setSendError] = useState(null);

  let weekday = new Array(7);
  weekday[0] = "Sunday";
  weekday[1] = "Monday";
  weekday[2] = "Tuesday";
  weekday[3] = "Wednesday";
  weekday[4] = "Thursday";
  weekday[5] = "Friday";
  weekday[6] = "Saturday";

  //Modal
  const [showModal, setShowModal] = useState();
  const fetchData = async () => {
    try {
      const location = await listAvailability(docId);
      setData(location);
    } catch (error) {
      setSendError(
        "Error when listing data. Please try again or contact support."
      );
    }
  };

  const handleCloseModal = () => {
    setShowModal();
  };
  const handleShoModal = (id) => {
    setShowModal(
      <Modal
        handleClose={handleCloseModal}
        title="Manage Availability"
        showComponent={
          <EditAvailability
            docId={docId}
            id={id}
            forceDataRefresh={fetchData}
          />
        }
      />
    );
  };

  const handleDeleleAvailability = async (id) => {
    try {
      await deleleAvailability(docId, id);
      toast.success("The selected data has been removed!");
      fetchData();
    } catch (error) {
      toast.warn(`Something went wrong. Availability hasn't been removed.`);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="row justify-content">
      {showModal}
      <div className="col-md-12">
        <div class="card">
          <div class="card-body">
            <div className="row">
              <div className="col-12 col-md-8">
                <h4 class="mt-0 header-title mb-4">Availability</h4>
              </div>
              <div className="col-12 col-md-4 text-right">
                <bottton
                  className="btn btn-primary"
                  onClick={() => handleShoModal(null)}
                >
                  Add new
                </bottton>
              </div>
            </div>

            <div class="table-responsive">
              {sendError ? (
                <div>{sendError}</div>
              ) : (
                <table class="table table-striped mb-0">
                  <thead>
                    <tr>
                      <th>Location</th>
                      <th>Period</th>
                      <th>Work Days</th>
                      <th>Status</th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>

                  {data && data.length === 0 ? (
                    <tr>
                      <td colSpan="6">
                        Availability not found. Click on Add new to create your
                        first register.
                      </td>
                    </tr>
                  ) : (
                    <tbody>
                      {data.map((item, index) => (
                        <tr key={index}>
                          <td>
                            <pre>{item.address}</pre>
                            <pre>
                              {item.state}/{item.city}
                            </pre>
                          </td>
                          <td>
                            {item.period_from} - {item.period_to}
                          </td>
                          <td>
                            {item.work_days.split(",").map((i, index) => (
                              <pre>{weekday[i]}</pre>
                            ))}
                          </td>
                          <td>
                            {item.status === 0 ? "Not Available" : "Available"}
                          </td>
                          <td>
                            <button
                              onClick={() => handleShoModal(item.id)}
                              className="btn btn-outline-info"
                            >
                              Edit
                            </button>
                          </td>
                          <td>
                            <button
                              onClick={() => handleDeleleAvailability(item.id)}
                              className="btn btn-outline-danger"
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  )}
                </table>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ListAvailability;
