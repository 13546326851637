import React, { useState, useEffect } from "react";
import {
  getNA,
  setNA as setNotAvailable,
  putNA,
} from "../../../services/notAvailable";

import Joi from "joi-browser";

function EditNotAvailable({ docId, id, forceDataRefresh }) {
  const [data, setData] = useState({});
  const [naId, setNAId] = useState(id);
  const [alertMsg, setAlertMessage] = useState({});

  const [errors, setErrors] = useState();

  const [schema] = useState({
    date: Joi.string().required().min(10).max(10).label("Date"),
    from: Joi.string().required().min(5).max(5).label("Period From"),
    to: Joi.string().required().min(5).max(5).label("Period From"),
  });

  const validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(data, schema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  const handleUpdate = async () => {
    try {
      await putNA(naId, data);
      forceDataRefresh();
      setAlertMessage({
        type: "success",
        message: "Your data has been updated!",
      });
    } catch (error) {
      setAlertMessage({ type: "warning", message: "Something went wrong!" });
    } finally {
      setTimeout(() => {
        setAlertMessage({});
      }, 7000);
    }
  };

  const handleAdd = async () => {
    try {
      let newId = await setNotAvailable(docId, data);
      console.log(newId);
      newId = parseInt(newId, 10);
      setNAId(newId);
      forceDataRefresh();
      setAlertMessage({
        type: "success",
        message: "A new N/A register has been created!",
      });
    } catch (error) {
      console.log(error);
      setAlertMessage({ type: "warning", message: "Something went wrong!" });
    } finally {
      setTimeout(() => {
        setAlertMessage({});
      }, 7000);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const errors = validate();
    setErrors(errors);
    if (errors) return;

    if (naId !== null && !isNaN(naId)) {
      handleUpdate();
    } else {
      handleAdd();
    }
  };

  //getNA(docId, id)
  const fetchData = async (docId, id) => {
    const data = await getNA(id);
    setData({
      date: data.date,
      from: data.from,
      to: data.to,
    });
  };

  useEffect(() => {
    if (id && id !== null) {
      fetchData(docId, id);
    }
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-12">
          <div class="form-group">
            <label>Date</label>
            <input
              type="date"
              placeholder=""
              class="form-control"
              value={data.date || ""}
              id="date"
              onChange={(e) => setData({ ...data, date: e.target.value })}
            />
            {errors && errors.date && (
              <div className="alert alert-danger py-1 mt-2">{errors.date}</div>
            )}
          </div>

          <div class="form-group">
            <label>From</label>
            <input
              type="text"
              placeholder="00:00"
              class="form-control"
              value={data.from || ""}
              id="from"
              onChange={(e) => setData({ ...data, from: e.target.value })}
            />
            {errors && errors.from && (
              <div className="alert alert-danger py-1 mt-2">{errors.from}</div>
            )}
          </div>

          <div class="form-group">
            <label>To</label>
            <input
              type="text"
              placeholder="00:00"
              class="form-control"
              value={data.to || ""}
              id="to"
              onChange={(e) => setData({ ...data, to: e.target.value })}
            />
            {errors && errors.to && (
              <div className="alert alert-danger py-1 mt-2">{errors.to}</div>
            )}
          </div>
        </div>
        <div className="col-12 text-left text-md-right">
          <button className="btn btn-primary w-50">Save</button>
        </div>
        {alertMsg.type && (
          <div className="col-12 text-left text-md-left mt-3">
            <div class={`alert alert-${alertMsg.type}`} role="alert">
              {alertMsg.message}
            </div>
          </div>
        )}
      </div>
    </form>
  );
}

export default EditNotAvailable;
