import axios from "axios";
import { apiURL } from "../config.json";

const apiEndPoint = `${apiURL}/notavailable`;

export async function setNA(docId, data) {
  const result = await axios.post(`${apiEndPoint}/${docId}`, data);
  return result.data;
}

export async function getNA(id) {
  try {
    const response = await axios.get(`${apiEndPoint}/${id}`);
    return response.data;
  } catch (error) {
    throw new Error("Error");
  }
}

export async function getNAByPeriod(id, from, to) {
  try {
    const response = await axios.get(
      `${apiEndPoint}/byperiod/${id}/${from}/${to}`
    );
    return response.data;
  } catch (error) {
    throw new Error("Error");
  }
}

export async function listNA(docId) {
  try {
    const response = await axios.get(`${apiEndPoint}/all/${docId}`);
    return response.data;
  } catch (error) {
    throw new Error("Error");
  }
}

export async function putNA(id, data) {
  try {
    const response = await axios.put(`${apiEndPoint}/${id}`, data);
    return response.data;
  } catch (error) {
    throw new Error("Error");
  }
}

export async function deleleNA(id) {
  const response = await axios.delete(`${apiEndPoint}/${id}`);
  return response.data;
}

export default {
  setNA,
  getNA,
  listNA,
  putNA,
  deleleNA,
};
