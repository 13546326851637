import React, { useState, useEffect } from "react";
import { listLocations } from "../../services/locations";
import { searchDocSpec } from "../../services/docs";

const DoctorForm = ({ setDoc, doc }) => {
  const [keyWord, setKeyWord] = useState();
  const [docList, setDocList] = useState([]);
  const [docListSearching, setDocListSearching] = useState(false);
  const [locationsData, setLocationsData] = useState([]);

  useEffect(() => {
    if (keyWord && keyWord.length >= 3) {
      fetchDocs();
    } else {
      setDocList([]);
    }
  }, [keyWord]);

  const fetchDocs = async () => {
    try {
      setDocListSearching(true);
      setDocList([]);
      const data = await searchDocSpec(keyWord);
      setDocList(data);
      setDocListSearching(false);
    } catch (error) {
      console.log("Invalid search!");
      setDocListSearching(false);
    }
  };

  const fetchLocations = async (id_doc) => {
    try {
      const data = await listLocations(id_doc);
      let locList = [
        { id: "", name: "" },
        { id: 0, name: "IME by Video Link" },
      ];
      data.map((item) =>
        locList.push({
          id: item.id,
          name: `${item.state} - ${item.city} / Postcode: ${item.postcode}`,
        })
      );
      setLocationsData(locList);
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateSelect = (value) => {
    setDoc({ ...doc, locationId: parseInt(value) });
  };

  const handleDocClick = (id, name, speciality) => {
    setDoc({
      id,
      name,
      speciality,
    });
    setDocList([]);
    setKeyWord();
    fetchLocations(id);
  };

  useEffect(() => {
    if (doc.id) {
      fetchLocations(doc.id);
    }
  }, [doc.id]);

  return (
    <div className="row">
      <div className="col">
        {doc.id ? (
          <p className="mt-2 mb-2">
            <strong>Doctor:</strong>
            <br />
            {doc.name}{" "}
            <span
              className="badge badge-primary ml-2 pointer"
              onClick={() => setDoc({})}
            >
              Change
            </span>
          </p>
        ) : (
          <p className="mt-2 mb-2">
            Search a Doctor by name or speciality:
            <br />
            <input
              type="text"
              value={keyWord}
              onChange={(e) => setKeyWord(e.target.value)}
            />
            {docListSearching && "Processing..."}
            {docList && docList.length > 0 && (
              <ul className="autoCompleteUL">
                {docList &&
                  docList.length > 0 &&
                  docList.map((item, key) => (
                    <li
                      key={key}
                      onClick={() =>
                        handleDocClick(
                          item.id,
                          `${item.title} ${item.name} - ${item.speciality}`,
                          item.speciality
                        )
                      }
                    >
                      {item.title} {item.name} - {item.speciality}
                    </li>
                  ))}
              </ul>
            )}
          </p>
        )}
        {doc.id && (
          <p>
            <u>Location:</u>
            <select
              class="form-control"
              value={doc.locationId}
              onChange={(e) => handleUpdateSelect(e.target.value)}
            >
              {locationsData.map((item, index) => (
                <option value={item.id} key={index}>
                  {item.name}
                </option>
              ))}
            </select>
          </p>
        )}
      </div>
    </div>
  );
};

export default DoctorForm;
