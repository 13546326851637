import { update } from "lodash";
import React, { useState } from "react";

import { updateAppoint } from "../../services/appointments";

function EditAdminComments({ adminComments, appId, hasBeenUpdated }) {
  const [edit, setEdit] = useState(false);
  const [currentAdminComments, setCurrentAdminComments] =
    useState(adminComments);
  const [newAdminContent, setNewAdminContent] = useState(adminComments);
  const [errMsg, setErrMsg] = useState(false);

  const handleUpdate = async () => {
    try {
      await updateAppoint(appId, { adminComments: newAdminContent });
      setCurrentAdminComments(newAdminContent);
      hasBeenUpdated();
    } catch (error) {
      setErrMsg(true);
      setTimeout(() => {
        setErrMsg(false);
      }, 4000);
    } finally {
      setEdit(false);
    }
  };

  const handleCancel = () => {
    setNewAdminContent(currentAdminComments);
    setEdit(false);
  };

  return (
    <div className="col-12 col-md-6 mb-2 d-block">
      <strong className="d-block">Admin Comments:</strong>
      {!edit ? (
        <p>
          {currentAdminComments}
          <span
            className="badge badge-primary ml-2 pointer"
            style={{ fontSize: "1em" }}
            onClick={() => setEdit(true)}
          >
            Edit
          </span>
        </p>
      ) : (
        <>
          <textarea
            type="textarea"
            className="form-control mb-2"
            id="comments"
            value={newAdminContent}
            onChange={(e) =>
              setNewAdminContent(e.target.value.substring(0, 500))
            }
          />
          <span className="btn btn-success mr-2" onClick={handleUpdate}>
            Update
          </span>
          <span className="btn btn-danger" onClick={handleCancel}>
            Cancel
          </span>
        </>
      )}
      {errMsg && (
        <div class="alert alert-warning" role="alert">
          Something went wrong. Please try again.
        </div>
      )}
    </div>
  );
}

export default EditAdminComments;
