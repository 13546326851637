import React from "react";
import { Link } from "react-router-dom";

const breadcrumb = ({ title, path }) => {
  return (
    <div className="page-title-box">
      <div className="row align-items-center ">
        <div className="col-md-8">
          <div className="page-title-box">
            <h4 className="page-title">{title}</h4>
            {path && Array.isArray(path) && (
              <ol className="breadcrumb">
                {path.map((item,index) => (
                  <li className="breadcrumb-item" key={index}>
                    <Link to={item.url || "#"}>{item.title}</Link>
                  </li>
                ))}
              </ol>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default breadcrumb;
