import React, { useState, useEffect } from "react";
// FullCalendar

import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import listPlugin from "@fullcalendar/list";
import format from "date-format";

import Modal from "../layout/modal";
import AppointmentDetails from "../appointments/appointmentDetails";
import { listEvents, exportXLS, exportPDF } from "../../services/agenda";
import { searchDocs } from "../../services/searchdoc";
import web from "../../services/web";
import { listSpecs } from "../../services/specs";
import { apiURL } from "../../config.json";
import ManageAppointments from "../appointments/index";

function Agenda(props) {
  const [data, setData] = useState([]);
  const [myModal, setMyModal] = useState();
  const [filters, setFilters] = useState({});
  const [docQuery, setDocQuery] = useState();
  const [docList, setDocList] = useState();
  const [docFilterMsg, setDocFilterMsg] = useState();
  const [locationsList, setLocationsList] = useState([]);
  const [specsList, setSpecsList] = useState([]);
  const [filePath, setFilePath] = useState();
  const [filePathPDF, setFilePathPDF] = useState();
  const [pdfProcessing, setPdfProcessing] = useState(false);

  const handleCloseModal = () => {
    setMyModal();
  };

  const handleData = async () => {
    const eventsList = await listEvents(filters);
    setData(eventsList);
    setFilePath();
  };

  const hasBeenUpdated = () => {
    handleData();
  };

  const fetchDoctors = async () => {
    try {
      setDocFilterMsg("Processing...");
      const list = await searchDocs(docQuery);
      setFilters({ ...filters, doctors: [] });

      if (!list || list.length === 0) {
        setDocList();
        return setDocFilterMsg("Doctor not found...");
      }
      setDocFilterMsg();
      setDocList(list);
    } catch (error) {
      setDocFilterMsg("Failed...");
    }
  };

  const fetchLocations = async () => {
    try {
      const list = await web.getLocation();
      setLocationsList(list);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchSpecs = async () => {
    try {
      const list = await listSpecs();
      setSpecsList(list);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (docQuery && docQuery.length >= 3) {
      fetchDoctors();
    }
  }, [docQuery]);

  useEffect(() => {
    const date = new Date();
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 2, 0);

    setFilters({
      from: format("yyyy-MM-dd", firstDay),
      to: format("yyyy-MM-dd", lastDay),
      status: ["confirmed", "pending"],
    });

    fetchLocations();
    fetchSpecs();
  }, []);

  const handleCheckBox = (type, id) => {
    let list = filters[type];

    if (!Array.isArray(list)) {
      list = [];
    }

    const index = list.indexOf(id);

    if (index === -1) {
      list.push(id);
    } else {
      list.splice(index, 1);
    }

    setFilters({ ...filters, [type]: [...list] });
  };

  const handleEventClick = (e) => {
    setMyModal(
      <Modal
        title="Event Details"
        handleClose={handleCloseModal}
        showComponent={
          <AppointmentDetails
            appId={e.event._def.publicId}
            hasBeenUpdated={hasBeenUpdated}
          />
        }
        xl={true}
      />
    );
  };

  const handleExportXlsx = async () => {
    const filePath = await exportXLS(filters);
    setFilePath(filePath);
  };

  const handleExportPDF = async () => {
    setPdfProcessing(true);
    let list = [];
    for (let i = 0; i < data.length; i++) {
      list.push(data[i].id);
    }
    const filePath = await exportPDF(list);
    setFilePathPDF(filePath);
    setPdfProcessing(false);
  };

  const handleCreateAppoint = () => {
    setMyModal(
      <Modal
        title="Event Details"
        handleClose={handleCloseModal}
        showComponent={<ManageAppointments onClose={handleCloseModal} />}
        xl={true}
      />
    );
  };

  return (
    <div className="row">
      {myModal && myModal}

      <div className="col-12 col-md-10">
        <FullCalendar
          headerToolbar={{
            start: "title", // will normally be on the left. if RTL, will be on the right
            center: "",
            end: "dayGridDay,dayGridWeek,dayGridMonth today prev,next", // will normally be on the right. if RTL, will be on the left
          }}
          height={850}
          eventClick={(e) => handleEventClick(e)}
          plugins={[dayGridPlugin, listPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          events={data}
          datesSet={(dateInfo) => handleData(dateInfo)}
        />
        {!filePath ? (
          <button className="btn btn-primary mt-2" onClick={handleExportXlsx}>
            Export Xlsx
          </button>
        ) : (
          <>
            {filePath === "error" ? (
              <span>
                Something went wrong, please, try again.{" "}
                <button
                  className="btn btn-danger mt-2 ml-1"
                  onClick={() => setFilePath()}
                >
                  Close
                </button>
              </span>
            ) : (
              <span>
                <button
                  className="btn btn-success mt-2"
                  onClick={() => {
                    window.open(`${apiURL}${filePath}`, "_blank");
                    return null;
                  }}
                >
                  Download Xlsx
                </button>
                <button
                  className="btn btn-danger mt-2 ml-1"
                  onClick={() => setFilePath()}
                >
                  Close
                </button>
              </span>
            )}
          </>
        )}

        {!pdfProcessing ? (
          <>
            {!filePathPDF ? (
              <button
                className="btn btn-primary mt-2 ml-2"
                onClick={handleExportPDF}
              >
                Export PDF
              </button>
            ) : (
              <>
                {filePathPDF === "error" ? (
                  <span>
                    Something went wrong, please, try again.{" "}
                    <button
                      className="btn btn-danger mt-2 ml-2"
                      onClick={() => setFilePathPDF()}
                    >
                      Close
                    </button>
                  </span>
                ) : (
                  <span>
                    <button
                      className="btn btn-success mt-2 ml-2"
                      onClick={() => {
                        window.open(`${apiURL}${filePathPDF}`, "_blank");
                        return null;
                      }}
                    >
                      Download PDF
                    </button>
                    <button
                      className="btn btn-danger mt-2 ml-1"
                      onClick={() => setFilePathPDF()}
                    >
                      Close
                    </button>
                  </span>
                )}
              </>
            )}
          </>
        ) : (
          <span className="mt-2 btn">Processing PDF...</span>
        )}

        <button
          className="btn btn-success mt-2 ml-2"
          onClick={handleCreateAppoint}
        >
          Create an Appointment
        </button>
      </div>
      <div className="col-12 col-md-2 bg-gray">
        <h2>Filters</h2>
        <div className="form-group">
          <label className="d-block">Doctors:</label>
          <input
            type="text"
            placeholder=""
            class="form-control"
            value={docQuery || ""}
            id="doctor"
            autocomplete="off"
            onChange={(e) => setDocQuery(e.target.value)}
          />
          {docFilterMsg}
          <div class="mt-4">
            {docList &&
              docList.map((item, index) => (
                <div
                  class="custom-control custom-checkbox"
                  key={index}
                  onClick={() => handleCheckBox("doctors", item.id)}
                >
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id={index}
                    checked={
                      filters &&
                      filters.doctors &&
                      filters.doctors.indexOf(item.id) > -1
                        ? true
                        : false
                    }
                  />
                  <label class="custom-control-label" for="customCheck1">
                    {item.title} {item.name}
                  </label>
                </div>
              ))}
            <span class="font-13 text-muted"></span>
          </div>
        </div>

        <div class="form-group">
          <label className="d-block">Locations:</label>

          <div class="mt-2">
            {locationsList.map((item, index) => (
              <div
                class="custom-control custom-checkbox"
                key={index}
                onClick={() =>
                  handleCheckBox("locations", `${item.state}/${item.city}`)
                }
              >
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id={index}
                  checked={
                    filters &&
                    filters.locations &&
                    filters.locations.indexOf(`${item.state}/${item.city}`) > -1
                      ? true
                      : false
                  }
                />
                <label class="custom-control-label" for="customCheck1">
                  {item.state} / {item.city}
                </label>
              </div>
            ))}
            <div
              class="custom-control custom-checkbox"
              onClick={() => setFilters({ ...filters, IME: !filters.IME })}
            >
              <input
                type="checkbox"
                class="custom-control-input"
                checked={filters && filters.IME === true ? true : false}
              />
              <label class="custom-control-label" for="customCheck1">
                IME by Video Link
              </label>
            </div>
            <span class="font-13 text-muted"></span>
          </div>
        </div>

        <div class="form-group">
          <label className="d-block">Period:</label>

          <div class="mt-2">
            <label class="d-block mb-0" style={{ fontWeight: 200 }}>
              From:
            </label>
            <input
              type="date"
              id="from"
              className="mb-2"
              value={filters.from || ""}
              onChange={(e) => setFilters({ ...filters, from: e.target.value })}
            />

            <label class="d-block mb-0" style={{ fontWeight: 200 }}>
              To:
            </label>
            <input
              type="date"
              id="to"
              value={filters.to || ""}
              onChange={(e) => setFilters({ ...filters, to: e.target.value })}
            />
          </div>
        </div>

        <div class="form-group">
          <label className="d-block">Specialities:</label>

          <div class="mt-2">
            {specsList.map((item, index) => (
              <div
                class="custom-control custom-checkbox"
                key={index}
                onClick={() => handleCheckBox("specilalities", item.speciality)}
              >
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id={index}
                  checked={
                    filters &&
                    filters.specilalities &&
                    filters.specilalities.indexOf(item.speciality) > -1
                      ? true
                      : false
                  }
                />
                <label class="custom-control-label" for="customCheck1">
                  {item.speciality}
                </label>
              </div>
            ))}
            <span class="font-13 text-muted"></span>
          </div>
        </div>

        <div class="form-group">
          <label className="d-block">Status:</label>

          <div class="mt-2">
            {["Confirmed", "Pending", "Cancelled"].map((item, index) => (
              <div
                class="custom-control custom-checkbox"
                key={index}
                onClick={() => handleCheckBox("status", item.toLowerCase())}
              >
                <input
                  type="checkbox"
                  class="custom-control-input"
                  checked={
                    filters &&
                    filters.status &&
                    filters.status.indexOf(item.toLowerCase()) > -1
                      ? true
                      : false
                  }
                  id={index}
                />
                <label class="custom-control-label" for="customCheck1">
                  {item}
                </label>
              </div>
            ))}
            <span class="font-13 text-muted"></span>
          </div>
        </div>
        <button className="btn btn-success w-100" onClick={handleData}>
          Filter
        </button>
      </div>
    </div>
  );
}

export default Agenda;
