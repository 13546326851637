import axios from "axios";
import { apiURL } from "../config.json";

const apiEndPoint = `${apiURL}/specs`;

export async function listSpecs() {
  try {
    const response = await axios.get(apiEndPoint);
    return response.data;
  } catch (error) {
    return [];
  }
}

export default { listSpecs };
