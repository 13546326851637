import React, { useState, useEffect } from "react";
import {
  getAvailability,
  setAvailability,
  putAvailability,
} from "../../../services/availability";

import { listLocations, setLocation } from "../../../services/locations";

function EditAvailability({ docId, id, forceDataRefresh }) {
  const [data, setData] = useState({});
  const [availabilityId, setAvailabilityId] = useState(id);
  const [alertMsg, setAlertMessage] = useState({});
  const [locations, setLocations] = useState([]);

  let weekday = new Array(7);
  weekday[0] = "Sunday";
  weekday[1] = "Monday";
  weekday[2] = "Tuesday";
  weekday[3] = "Wednesday";
  weekday[4] = "Thursday";
  weekday[5] = "Friday";
  weekday[6] = "Saturday";

  const handleUpdateAvailability = async () => {
    try {
      await putAvailability(docId, availabilityId, data);
      forceDataRefresh();
      setAlertMessage({
        type: "success",
        message: "Your data has been updated!",
      });
    } catch (error) {
      setAlertMessage({ type: "warning", message: "Something went wrong!" });
    } finally {
      setTimeout(() => {
        setAlertMessage({});
      }, 7000);
    }
  };

  const handleAddAvailability = async () => {
    try {
      const newAvailability = await setAvailability(docId, data);
      setAvailabilityId(newAvailability);
      forceDataRefresh();
      setAlertMessage({
        type: "success",
        message: "The new Availability has been created!",
      });
    } catch (error) {
      setAlertMessage({ type: "warning", message: "Something went wrong!" });
    } finally {
      setTimeout(() => {
        setAlertMessage({});
      }, 7000);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (availabilityId !== null && !isNaN(availabilityId)) {
      handleUpdateAvailability();
    } else {
      handleAddAvailability();
    }
  };

  const fetchData = async (docId, id) => {
    const availability = await getAvailability(docId, id);
    availability.status = availability.status.toString();
    availability.work_days = availability.work_days.split(",");
    for (let i = 0; i < availability.work_days.length; i++) {
      availability.work_days[i] = parseInt(availability.work_days[i]);
    }
    setData(availability);
  };

  const fetchLocations = async () => {
    try {
      const data = await listLocations(docId);
      setLocations(data);
    } catch (error) {
      setAlertMessage({ type: "warning", message: "Something went wrong!" });
      setTimeout(() => {
        setAlertMessage({});
      }, 7000);
    }
  };

  const handleWorkDays = (item) => {
    let workDays = data.work_days || [];
    const index = workDays.indexOf(item);
    if (index > -1) {
      workDays.splice(index, 1);
    } else {
      workDays.push(item);
      workDays.sort();
    }
    setData({ ...data, work_days: workDays });
  };
  useEffect(() => {
    if (id && id !== null) {
      fetchData(docId, id);
    }
    fetchLocations();
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      {locations && locations.length === 0 ? (
        <div>You need at least one location to create an availability.</div>
      ) : (
        <div className="row">
          <div className="col-md-6">
            <div class="form-group">
              <label>Location</label>

              <select
                class="form-control"
                value={data.id_location || ""}
                onChange={(e) =>
                  setData({ ...data, id_location: e.target.value })
                }
                id="id_location"
              >
                <option value=""></option>
                {locations.map((item, index) => (
                  <option value={item.id} key={index}>
                    {item.city} - {item.address.substring(0, 30)}
                  </option>
                ))}
              </select>
              <span class="font-13 text-muted"></span>
            </div>
            <div class="form-group">
              <label className="d-block">Period</label>
              <span class="font-13 text-muted">From</span>
              <input
                type="text"
                placeholder=""
                class="form-control"
                value={data.period_from || "08:00"}
                id="period_from"
                onChange={(e) =>
                  setData({ ...data, period_from: e.target.value })
                }
                maxLength="5"
              />
              <span class="font-13 text-muted">To</span>
              <input
                type="text"
                placeholder=""
                class="form-control"
                value={data.period_to || "17:00"}
                id="period_to"
                onChange={(e) =>
                  setData({ ...data, period_to: e.target.value })
                }
                maxLength="5"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div class="form-group">
              <label>Work Days</label>
              {weekday.map((item, index) => (
                <div
                  class="custom-control custom-checkbox"
                  key={index}
                  onClick={() => handleWorkDays(index)}
                >
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    checked={
                      data &&
                      data.work_days &&
                      data.work_days.indexOf(index) !== -1
                        ? true
                        : false
                    }
                    id={index}
                  />
                  <label class="custom-control-label" for="customCheck1">
                    {item}
                  </label>
                </div>
              ))}
              <span class="font-13 text-muted"></span>
            </div>
            <div class="form-group">
              <label>Status</label>
              <select
                class="form-control"
                value={data.status && parseInt(data.status) === 0 ? 0 : 1}
                onChange={(e) => setData({ ...data, status: e.target.value })}
                id="status"
              >
                <option value={"1"}>Available</option>
                <option value={"0"}>Not Available</option>
              </select>
            </div>
          </div>
          <div className="col-12 text-left text-md-right">
            <button className="btn btn-primary w-50">Save</button>
          </div>
          {alertMsg.type && (
            <div className="col-12 text-left text-md-left mt-3">
              <div class={`alert alert-${alertMsg.type}`} role="alert">
                {alertMsg.message}
              </div>
            </div>
          )}
        </div>
      )}
    </form>
  );
}

export default EditAvailability;
