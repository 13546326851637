import axios from "axios";
import { apiURL } from "../config.json";

const apiEndPoint = `${apiURL}/emailtemplate`;

export async function listTemplates(status) {
  try {
    const response = await axios.get(
      apiEndPoint + `${status ? `?status=${status}` : ""}`
    );
    return response.data.results;
  } catch (error) {
    return [];
  }
}

export async function fetchTemplate(id) {
  try {
    const response = await axios.get(`${apiEndPoint}/${id}`);
    return response.data;
  } catch (error) {
    throw new Error("Error");
  }
}

export async function addTemplate(data) {
  const response = await axios.post(`${apiEndPoint}`, data);
  return response.data;
}

export async function updateTemplate(data, id) {
  const response = await axios.put(`${apiEndPoint}/${id}`, data);
  return response.data;
}

export async function deleleTemplate(id) {
  const response = await axios.delete(`${apiEndPoint}/${id}`);
  return response.data;
}

export async function sendEmail(data, userdetails) {
  const response = await axios.post(`${apiEndPoint}/sendemail`, {
    ...data,
    userdetails,
  });
  return response.data;
}

export default {
  listTemplates,
  fetchTemplate,
  addTemplate,
  updateTemplate,
  deleleTemplate,
};
