import axios from "axios";
import { apiURL } from "../config.json";

const apiEndPoint = `${apiURL}/log`;

export async function listLog(appoint_id) {
  try {
    const response = await axios.get(`${apiEndPoint}/${appoint_id}`);
    return response.data;
  } catch (error) {
    throw new Error("Error");
  }
}
export default {
  listLog,
};
