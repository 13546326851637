import axios from "axios";
import { apiURL } from "../config.json";

const apiEndPoint = `${apiURL}/newavailabledates`;

export async function setNewDate(docId, data) {
  //setNA
  const result = await axios.post(`${apiEndPoint}/${docId}`, data);
  return result.data;
}

export async function getDate(id) {
  //getNA
  try {
    const response = await axios.get(`${apiEndPoint}/${id}`);
    return response.data;
  } catch (error) {
    throw new Error("Error");
  }
}

export async function getNewAvailableByPeriod(id, dateFrom, dateTo) {
  try {
    const response = await axios.get(
      `${apiEndPoint}/byperiod/${id}/${dateFrom}/${dateTo}`
    );
    return response.data;
  } catch (error) {
    throw new Error("Error");
  }
}

export async function listDate(docId) {
  //listNA
  try {
    const response = await axios.get(`${apiEndPoint}/all/${docId}`);
    return response.data;
  } catch (error) {
    throw new Error("Error");
  }
}

export async function putDate(id, data) {
  //putNA
  try {
    const response = await axios.put(`${apiEndPoint}/${id}`, data);
    return response.data;
  } catch (error) {
    throw new Error("Error");
  }
}

export async function deleleDate(id) {
  //deleleNA
  const response = await axios.delete(`${apiEndPoint}/${id}`);
  return response.data;
}

export default {
  setNewDate,
  getDate,
  listDate,
  putDate,
  deleleDate,
};
