import axios from "axios";
import { apiURL } from "../config.json";

const apiEndPoint = `${apiURL}/locations`;

export async function listLocations(docId) {
  try {
    const response = await axios.get(`${apiEndPoint}/${docId}`);
    return response.data;
  } catch (error) {
    throw new Error("Error");
  }
}

export async function getLocation(docId, id) {
  try {
    const response = await axios.get(`${apiEndPoint}/${docId}/${id}`);
    return response.data;
  } catch (error) {
    throw new Error("Error");
  }
}

export async function setLocation(docId, data) {
  const response = await axios.post(`${apiEndPoint}/${docId}`, data);
  return response.data;
}

export async function putLocation(doctorId, id, data) {
  const response = await axios.put(`${apiEndPoint}/${doctorId}/${id}`, data);
  return response.data;
}

export async function deleleLocation(doctorId, id) {
  const response = await axios.delete(`${apiEndPoint}/${doctorId}/${id}`);
  return response.data;
}

export async function getCities(state) {
  try {
    const response = await axios.get(`${apiEndPoint}/cities/${state}`);
    return response.data;
  } catch (error) {
    throw new Error("Error");
  }
}

export default {
  listLocations,
  getLocation,
  setLocation,
  putLocation,
  deleleLocation,
  getCities,
};
