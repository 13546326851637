import axios from "axios";
import { apiURL } from "../config.json";

const apiEndPoint = `${apiURL}/injuries`;

export async function injuries() {
  try {
    const response = await axios.get(apiEndPoint);
    return response.data;
  } catch (error) {
    return [];
  }
}

export default { injuries };
