import React from "react";

const DoctorSummaryData = ({ data, setIsEditing, docSpecsList, specs }) => {
  const {
    title,
    name,
    email,
    phone,
    by_video,
    call_to_book,
    home_visit,
    status,
  } = data;
  return (
    <div className="row">
      <div className="col-md-6">
        <div className="col-12 p-2">
          <strong>Name:</strong> {title} {name}
        </div>
        <div className="col-12 p-2">
          <strong>Email:</strong> {email}
        </div>
        <div className="col-12 p-2">
          <strong>Phone Number:</strong> {phone}
        </div>
        <div className="col-12 p-2">
          <i
            className={`dripicons-${by_video === 1 ? "checkmark" : "cross"}`}
          ></i>{" "}
          Request IME by Video Link Available
          <br />
          <i
            className={`dripicons-${home_visit === 1 ? "checkmark" : "cross"}`}
          ></i>{" "}
          Home Visit Available
          <br />
          <i
            className={`dripicons-${
              call_to_book === 1 ? "checkmark" : "cross"
            }`}
          ></i>{" "}
          Call to Book
        </div>
      </div>
      <div className="col-md-6">
        <div className="col-12 p-2">
          <strong>Specialities:</strong>
          {docSpecsList &&
            docSpecsList.map((item) => (
              <h6 key={item} className="mt-2">
                {specs.find((e) => e.id === item.id)?.speciality || "Error"}
                <br />
                <span class="badge badge-info">
                  Session: {item.session_time} minutes
                </span>
              </h6>
            ))}
        </div>
        <div className="col-12 p-2">
          <strong>Status:</strong>{" "}
          {status === "1" ? "Available" : "Not Available"}
        </div>
        <div className="col-12 p-2">
          <bottton
            class="btn btn-primary w-25"
            onClick={() => setIsEditing(true)}
          >
            <i class="dripicons-pencil"></i> Edit
          </bottton>
        </div>
      </div>
    </div>
  );
};

export default DoctorSummaryData;
