import React, { useState, useEffect } from "react";
import { listUsers, deleteUser } from "../../services/user";
import Breadcrumb from "../layout/breadcrumb";
import Modal from "../layout/modal";
import CreateUser from "./create";
import EditUser from "./update";

function ManageUser() {
  const [data, setData] = useState([]);
  const [myModal, setMyModal] = useState();
  const [deleteUserName, setDeleteUsername] = useState();

  const handleCreateUser = () => {
    setMyModal(
      <Modal
        title="Create User"
        handleClose={handleCloseModal}
        showComponent={<CreateUser />}
        xl={false}
      />
    );
  };

  const handleUpdateUser = (data) => {
    setMyModal(
      <Modal
        title="Edit User"
        handleClose={handleCloseModal}
        showComponent={<EditUser details={data} />}
        xl={false}
      />
    );
  };

  const fetchData = async () => {
    try {
      setData();
      const list = await listUsers();
      setData(list);
    } catch (error) {
      console.log({ error });
      setData();
    }
  };

  const handleCloseModal = () => {
    setMyModal();
    fetchData();
  };

  const checkParams = (item, param) => {
    const result = item.filter((e) => e.Name === param);

    if (result.length > 0) {
      return result[0].Value;
    }
    return "-";
  };

  const handleDeleteUser = async (userName) => {
    try {
      await deleteUser(userName);
      fetchData();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="content">
      <div className="container-fluid">
        <Breadcrumb
          title="User Management"
          path={[
            { title: "Home", url: "/" },
            { title: "User Management", url: "#" },
          ]}
        />
        {myModal && myModal}
        <div className="row justify-content">
          <div className="col-md-12">
            <div class="card">
              <div class="card-body">
                <div className="row">
                  <div className="col-12 col-md-8">
                    <h4 class="mt-0 header-title mb-4">List of users</h4>
                  </div>
                  <div className="col-12 col-md-4 text-right">
                    <button
                      className="btn btn-primary"
                      onClick={handleCreateUser}
                    >
                      Add new user
                    </button>
                  </div>
                </div>

                <div class="table-responsive">
                  <table class="table table-striped mb-0">
                    <thead>
                      <tr>
                        <th>Username</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Mobile Number</th>

                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {data &&
                        data.map((item, key) => (
                          <tr key={key}>
                            <td>{item.Username}</td>
                            <td>
                              {checkParams(item.Attributes, "given_name")}{" "}
                              {checkParams(item.Attributes, "family_name")}
                            </td>
                            <td>{checkParams(item.Attributes, "email")}</td>
                            <td>
                              {checkParams(item.Attributes, "phone_number")}
                            </td>
                            <td>
                              <button
                                className="btn btn-outline-info"
                                onClick={() =>
                                  handleUpdateUser({
                                    username: item.Username,
                                    phone_number: checkParams(
                                      item.Attributes,
                                      "phone_number"
                                    ),
                                    given_name: checkParams(
                                      item.Attributes,
                                      "given_name"
                                    ),
                                    family_name: checkParams(
                                      item.Attributes,
                                      "family_name"
                                    ),
                                    email: checkParams(
                                      item.Attributes,
                                      "email"
                                    ),
                                  })
                                }
                              >
                                Edit
                              </button>
                            </td>
                            <td>
                              {deleteUserName &&
                              deleteUserName === item.Username ? (
                                <>
                                  Do you want to delete this user?
                                  <br />
                                  <button
                                    className="btn btn-danger mr-1"
                                    onClick={() =>
                                      handleDeleteUser(item.Username)
                                    }
                                  >
                                    Delete
                                  </button>
                                  <button
                                    className="btn btn-primary"
                                    onClick={() => setDeleteUsername()}
                                  >
                                    Keep
                                  </button>
                                </>
                              ) : (
                                <button
                                  className="btn btn-outline-danger"
                                  onClick={() =>
                                    setDeleteUsername(item.Username)
                                  }
                                >
                                  Delete
                                </button>
                              )}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ManageUser;
