import React from "react";

function Modal({ handleClose, title, showComponent, xl }) {
  return (
    <div
      class="modal fade bs-example-modal-center show"
      tabindex="-1"
      role="dialog"
      aria-labelledby="mySmallModalLabel"
      style={{ display: "block" }}
      aria-modal="true"
    >
      <div
        class={`modal-dialog modal-dialog-centered ${
          xl && xl === true && "modal-xl"
        }`}
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title mt-0">{title}</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={handleClose}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">{showComponent}</div>
        </div>
      </div>
    </div>
  );
}

export default Modal;
