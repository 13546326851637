import React, { useState } from "react";
import ListOfDoctors from "./listOfDoctors";
import EditDoctor from "./editDoctor";

function Doctors() {
  const [selectedDoctor, setSelectedDoctor] = useState();

  const handleDoctor = (id) => {
    setSelectedDoctor(id);
  };

  return (
    <div className="content">
      <div className="container-fluid">
        {!selectedDoctor ? (
          <ListOfDoctors handleDoctor={handleDoctor} />
        ) : (
          <EditDoctor handleDoctor={handleDoctor} docId={selectedDoctor} />
        )}
      </div>
    </div>
  );
}

export default Doctors;
