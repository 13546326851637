import axios from "axios";
import { apiURL } from "../config.json";

const apiEndPoint = `${apiURL}/docs`;

export async function listDocs() {
  try {
    const response = await axios.get(apiEndPoint);
    return response.data;
  } catch (error) {
    return [];
  }
}

export async function fetchDoc(id) {
  try {
    const response = await axios.get(`${apiEndPoint}/${id}`);
    return response.data;
  } catch (error) {
    throw new Error("Error");
  }
}

export async function fetchRelDocSpecs(id) {
  try {
    const response = await axios.get(`${apiEndPoint}/rel/specs/${id}`);
    return response.data;
  } catch (error) {
    return null;
  }
}

export async function addDoctor(data) {
  const response = await axios.post(`${apiEndPoint}`, data);
  return response.data;
}

export async function updateDoctor(data, doctorId) {
  const response = await axios.put(`${apiEndPoint}/${doctorId}`, data);
  return response.data;
}

export async function deleleDoctor(doctorId) {
  const response = await axios.delete(`${apiEndPoint}/${doctorId}`);
  return response.data;
}

export async function searchDocSpec(keyword) {
  const response = await axios.get(`${apiEndPoint}/search-doc-spec/${keyword}`);
  return response.data;
}

export default {
  listDocs,
  fetchDoc,
  fetchRelDocSpecs,
  addDoctor,
  updateDoctor,
  searchDocSpec,
};
