import React, { useState } from "react";
import { createUser } from "../../services/user";

const CreateUser = () => {
  const [data, setData] = useState({
    username: "",
    phone_number: "",
    given_name: "",
    family_name: "",
    email: "",
    temporaryPassword: "",
  });
  const [errorMsg, setErrorMsg] = useState();
  const [success, setSuccess] = useState(false);

  const onCreate = async () => {
    try {
      const result = await createUser(data);
      if (result.error) {
        setErrorMsg(result.error.message);
        setTimeout(() => {
          setErrorMsg();
        }, 4000);

        return;
      }
      setData({
        username: "",
        phone_number: "",
        given_name: "",
        family_name: "",
        email: "",
        temporaryPassword: "",
      });
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
      }, 4000);
    } catch (error) {
      console.log({ error });
    }
  };

  return (
    <div className="col-12 mb-4">
      <div className="border rounded border-primary p-4">
        <div className="row">
          <div className="col">
            First Name:
            <br />
            <input
              type="text"
              value={data.given_name}
              onChange={(e) => setData({ ...data, given_name: e.target.value })}
            />
          </div>
          <div className="col">
            Surname:
            <br />
            <input
              type="text"
              value={data.family_name}
              onChange={(e) =>
                setData({ ...data, family_name: e.target.value })
              }
            />
          </div>
        </div>

        <div className="row mt-2">
          <div className="col">
            Email:
            <br />
            <input
              type="text"
              value={data.email}
              onChange={(e) => setData({ ...data, email: e.target.value })}
            />
          </div>
          <div className="col">
            Mobile Number:
            <br />
            <input
              type="text"
              value={data.phone_number}
              onChange={(e) =>
                setData({ ...data, phone_number: e.target.value })
              }
            />
          </div>
        </div>

        <div className="row mt-2">
          <div className="col">
            User Name:
            <br />
            <input
              type="text"
              value={data.username}
              onChange={(e) => setData({ ...data, username: e.target.value })}
            />
          </div>
          <div className="col">
            Temporary Password:
            <br />
            <input
              type="text"
              value={data.temporaryPassword}
              onChange={(e) =>
                setData({ ...data, temporaryPassword: e.target.value })
              }
            />
          </div>
        </div>

        <div className="row mt-4">
          <div className="col">
            <button className="btn btn-success" onClick={onCreate}>
              Create User
            </button>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-12 text-danger">{errorMsg && errorMsg}</div>
        </div>

        <div className="row mt-2">
          <div className="col-12 text-success">
            {success && "User created successfully!"}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateUser;
