import React, { useState, useEffect } from "react";
import { listLocations, deleleLocation } from "../../../services/locations";
import Modal from "../../layout/modal";
import EditLocation from "./editLocation";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ListLocations({ docId }) {
  const [data, setData] = useState([]);
  const [sendError, setSendError] = useState(null);
  //Modal
  const [showModal, setShowModal] = useState();
  const fetchData = async () => {
    try {
      const location = await listLocations(docId);
      setData(location);
    } catch (error) {
      setSendError(
        "Error when listing location. Please try again or contact support."
      );
    }
  };

  const handleCloseModal = () => {
    setShowModal();
  };
  const handleShoModal = (id) => {
    setShowModal(
      <Modal
        handleClose={handleCloseModal}
        title="Manage Location"
        showComponent={
          <EditLocation docId={docId} id={id} forceDataRefresh={fetchData} />
        }
      />
    );
  };

  const deleteLocation = async (id) => {
    try {
      await deleleLocation(docId, id);
      toast.success("The selected location has been removed!");
      fetchData();
    } catch (error) {
      toast.warn(`Something went wrong. Location hasn't been removed.`);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="row justify-content">
      {showModal}
      <div className="col-md-12">
        <div class="card">
          <div class="card-body">
            <div className="row">
              <div className="col-12 col-md-8">
                <h4 class="mt-0 header-title mb-4">Locations</h4>
              </div>
              <div className="col-12 col-md-4 text-right">
                <bottton
                  className="btn btn-primary"
                  onClick={() => handleShoModal(null)}
                >
                  Add new
                </bottton>
              </div>
            </div>

            <div class="table-responsive">
              {sendError ? (
                <div>{sendError}</div>
              ) : (
                <table class="table table-striped mb-0">
                  <thead>
                    <tr>
                      <th>State/City</th>
                      <th>Address</th>
                      <th>Status</th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>

                  {data && data.length === 0 ? (
                    <tr>
                      <td colSpan="6">
                        Location not found. Click on Add new to create your
                        first location
                      </td>
                    </tr>
                  ) : (
                    <tbody>
                      {data.map((item, index) => (
                        <tr key={index}>
                          <td>
                            {item.state}/{item.city}
                          </td>
                          <td>
                            <pre>{item.address}</pre>
                          </td>
                          <td>
                            {item.status === 1 ? "Available" : "Not Available"}
                          </td>
                          <td>
                            <button
                              onClick={() => handleShoModal(item.id)}
                              className="btn btn-outline-info"
                            >
                              Edit
                            </button>
                          </td>
                          <td>
                            <button
                              onClick={() => deleteLocation(item.id)}
                              className="btn btn-outline-danger"
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  )}
                </table>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ListLocations;
