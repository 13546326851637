import React, { useState, useEffect } from "react";
import { EditMultiSelect, EditSelect } from "../../common/editField";
import { claimTypes } from "../../services/claimtypes";
import { injuries } from "../../services/injuries";

const TextField = ({ label, value, maxLength, onChange, type }) => {
  return (
    <p>
      <strong>{label}:</strong>
      <br />
      <input
        type={type || "text"}
        value={value}
        maxLength={maxLength || 255}
        onChange={onChange}
        style={{ width: "80%" }}
      />
    </p>
  );
};

const ClientForm = ({ client, setClient }) => {
  const [claimTypesData, setClaimTypesData] = useState([]);
  const [injuriesData, setInjuriesData] = useState([]);

  const fetchTypes = async () => {
    let data = await claimTypes();
    setClaimTypesData(data);
    setClient({ ...client, claim_type: "Other" });

    const injuriesData = await injuries();
    setInjuriesData(injuriesData);
  };

  const handleMultipleSelect = (id, value) => {
    let currentData = [];
    if (client[id]) {
      currentData = client[id].split(",");
    }
    if (
      currentData &&
      currentData.findIndex((element) => element === value) !== -1
    ) {
      const index = currentData.indexOf(value);
      if (index > -1) {
        currentData.splice(index, 1);
      }
    } else {
      currentData.push(value);
    }
    setClient({ ...client, [id]: currentData.toString() });
  };

  useEffect(() => {
    fetchTypes();
  }, []);

  const handleData = (id, value) => {
    setClient({ ...client, [id]: value });
  };

  return (
    <div className="row">
      <div className="col">
        <p>
          <strong>Client and Claim details</strong>
        </p>
        <div className="row">
          <div className="col-12 col-md-6">
            <TextField
              label="Name"
              value={client.name}
              onChange={(e) => setClient({ ...client, client: e.target.value })}
            />
          </div>
          <div className="col-12 col-md-6">
            <TextField
              label="Phone"
              maxLength={13}
              value={client.phone}
              onChange={(e) => setClient({ ...client, phone: e.target.value })}
            />
          </div>
          <div className="col-12 col-md-6">
            <TextField
              label="Email"
              value={client.email}
              onChange={(e) => setClient({ ...client, email: e.target.value })}
            />
          </div>
          <div className="col-12 col-md-6">
            <TextField
              label="Date of Birth"
              type="date"
              value={client.dt_birth}
              onChange={(e) =>
                setClient({ ...client, dt_birth: e.target.value })
              }
              maxLength={13}
            />
          </div>
          <div className="col-12 col-md-6">
            <TextField
              label="Accident Date"
              type="date"
              value={client.accident_date}
              onChange={(e) =>
                setClient({ ...client, accident_date: e.target.value })
              }
            />
          </div>
          <div className="col-12 col-md-6">
            <EditSelect
              edit={() => {}}
              initialValue={""}
              currentValue={client.claim_type}
              setData={handleData}
              id="claim_type"
              list={claimTypesData}
              label="Clain type"
            />
          </div>
          <div className="col-12 col-md-6">
            <EditMultiSelect
              edit={() => {}}
              initialValue={""}
              currentValue={
                client.injury_type ? client.injury_type.split(",") : ""
              }
              setData={handleMultipleSelect}
              id="injury_type"
              list={injuriesData}
              label="Injury"
            />
          </div>
          <div className="col-12 col-md-6">
            <TextField
              label="Address Home Visit"
              value={client.home_visit_claimants_address}
              onChange={(e) =>
                setClient({
                  ...client,
                  home_visit_claimants_address: e.target.value,
                })
              }
            />
          </div>
          <div className="col-12 col-md-6">
            <p>
              <strong>Comments:</strong>
              <br />
              <textarea
                onChange={(e) =>
                  setClient({ ...client, comments: e.target.value })
                }
                className="ml-1 w-100"
              >
                {client.comments}
              </textarea>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientForm;
