import React, { useState } from "react";
import Breadcrumb from "../layout/breadcrumb";
import { Link } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Agenda from "./agenda";

function Calendar() {
  const [selectedDoctor, setSelectedDoctor] = useState();

  const handleDoctor = (id) => {
    setSelectedDoctor(id);
  };

  return (
    <div className="content">
      <div className="container-fluid">
        <ToastContainer />
        <Breadcrumb
          title="Calendar"
          path={[
            { title: "Home", url: "/" },
            { title: "Calendar", url: "/calendar" },
          ]}
        />
        <div className="row justify-content">
          <div className="col-md-12">
            <div class="card">
              <div class="card-body">
                <div className="row">
                  <div className="col-12">
                    <Agenda />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Calendar;
