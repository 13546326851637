import Amplify from "aws-amplify";
import awsconfig from "./aws-exports";
import { withAuthenticator } from "@aws-amplify/ui-react";
import "./App.css";
import { Route, Switch } from "react-router-dom";
import Header from "./components/layout/header";
import Sidebar from "./components/layout/sidebar";
import Dashboard from "./components/dashboard";
import Doctors from "./components/doctors/doctors";
import Calendar from "./components/calendar/calendar";
import EditDoctor from "./components/doctors/editDoctor";
import AppointmentDetailsPage from "./components/appointments/appointmentDetailsPage";
import ManageAppointmemts from "./components/appointments";
import EmailTemplate from "./components/emailTemplate/emailTemplate";
import EditTemplate from "./components/emailTemplate/editTemplate";
import ManageUser from "./components/user/index";

Amplify.configure(awsconfig);

function App() {
  return (
    <div id="wrapper">
      <Header />
      <Sidebar />
      <Switch>
        <div class="content-page">
          <Route exact path="/" component={Dashboard} />
          <Route exact path="/doctors" component={Doctors} />
          <Route exact path="/doctor/:id" component={EditDoctor} />
          <Route exact path="/doctor/" component={EditDoctor} />
          <Route exact path="/calendar" component={Calendar} />
          <Route exact path="/email-template/:id" component={EditTemplate} />
          <Route exact path="/email-template/" component={EditTemplate} />
          <Route exact path="/email-templates" component={EmailTemplate} />
          <Route
            exact
            path="/appointment/create"
            component={ManageAppointmemts}
          />
          <Route
            exact
            path="/appointment/:id"
            component={AppointmentDetailsPage}
          />
          <Route exact path="/user-management" component={ManageUser} />
        </div>
      </Switch>
    </div>
  );
}

export default withAuthenticator(App);
