import React, { useState } from "react";
import CreateAppointment from "../createAppointment";
import AppointmentDetails from "./appointmentDetails";

const ManageAppointments = ({ onClose, defaultData }) => {
  const [id, setId] = useState();
  return (
    <>
      {id ? (
        <AppointmentDetails appId={id} />
      ) : (
        <CreateAppointment
          setId={setId}
          onClose={onClose}
          defaultData={defaultData || {}}
        />
      )}
    </>
  );
};

export default ManageAppointments;
