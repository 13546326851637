import React from "react";
import { Link } from "react-router-dom";
function Header(props) {
  return (
    <div className="topbar">
      <div className="topbar-left" style={{ backgroundColor: "white" }}>
        <Link to="/" className="logo">
          <img
            src="/Verify-Medico-Legal-Solutions-logo-small.png"
            className="logo-lg"
            alt=""
            height="62"
          />
          <img
            src="/Verify-Medico-Legal-Solutions-logo-small.png"
            className="logo-sm"
            alt=""
            height="62"
          />
        </Link>
      </div>

      <nav className="navbar-custom">
        <ul className="list-inline menu-left mb-0">
          <li className="float-left">
            <button className="button-menu-mobile open-left waves-effect">
              <i className="mdi mdi-menu"></i>
            </button>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default Header;
