import format from "date-format";
import { getFullDetails } from "../services/agenda";
import { calHoursToMinutes, calMinutesToHours } from "./time";

export function handlePeriods(from, to, interval, time) {
  const periods = [];
  for (let i = from; i <= to; i = interval + time + i) {
    if (i + time <= to) {
      periods.push({ from: i, to: i + time });
    }
  }
  return periods;
}

export async function handleAvailabilityDetails(
  doc_id,
  idLocation,
  from,
  to,
  sessionTime,
  intervalTime
) {
  const data = await getFullDetails(doc_id, idLocation, true, from, to);

  const { availabilities, appointments, new_dates, not_available } = data;
  const fromDate = new Date(from);
  const availableSpots = addAppointments(appointments);
  //Handle availabilities
  for (let i = 0; i < availabilities.length; i++) {
    const workDays = availabilities[i].work_days.split(",");
    for (let j = 0; j < workDays.length; j++) {
      const eventDay = format("yyyy-MM-dd", fromDate.addDays(workDays[j]));
      const periods = handlePeriods(
        calHoursToMinutes(availabilities[i].period_from),
        calHoursToMinutes(availabilities[i].period_to),
        intervalTime,
        sessionTime
      );
      let checkAvailability = null;
      for (let k = 0; k < periods.length; k++) {
        checkAvailability = availableSpots.find(
          (e) =>
            e.appointDate === eventDay &&
            e.period_type === "appointment" &&
            ((e.startMinutes >= periods[k].from &&
              e.startMinutes < periods[k].to) ||
              (e.endMinutes > periods[k].from && e.endMinutes <= periods[k].to))
        );
        if (!checkAvailability) {
          let spot = {
            title: `Available time (${sessionTime} min)`,
            start: `${eventDay}T${calMinutesToHours(periods[k].from)}`,
            end: `${eventDay}T${calMinutesToHours(periods[k].to)}`,
            color: "#0b6ab7",
            classNames: "fc-custom-available-time",
            appointDate: eventDay,
            startMinutes: periods[k].from,
            endMinutes: periods[k].to,
            period_type: "available",
          };
          if (!matchNotAvailable(not_available, spot)) {
            availableSpots.push(spot);
          }
        }
      }
    }
  }
  //handle NewAvailabilities
  const newAvailableSpots = getNewAvailabilities(
    new_dates,
    intervalTime,
    sessionTime
  );

  let checkAvailability;
  for (let i = 0; i < newAvailableSpots.length; i++) {
    const spot = newAvailableSpots[i];
    checkAvailability = availableSpots.find(
      (e) =>
        e.appointDate === spot.appointDate &&
        ((e.startMinutes >= spot.startMinutes &&
          e.startMinutes < spot.endMinutes) ||
          (e.endMinutes > spot.startMinutes && e.endMinutes <= spot.endMinutes))
    );
    if (!checkAvailability) {
      if (!matchNotAvailable(not_available, spot)) {
        availableSpots.push(spot);
      }
    }
  }

  return availableSpots;
}

const addAppointments = (appointments) => {
  const data = [];
  for (let i = 0; i < appointments.length; i++) {
    const appoint = appointments[i];
    const { lawfirm, lawfirm_ref, date, period_from, period_to, id, status } =
      appoint;
    data.filter(
      (item) => !item.period_type === "available" && !item.appointDate === date
    );
    let statusColor = "#0bb714";
    if (status === "pending") {
      statusColor = "#696969";
    }
    data.push({
      title: `${lawfirm} - ${lawfirm_ref}`,
      start: `${date}T${calMinutesToHours(period_from)}`,
      end: `${date}T${calMinutesToHours(period_to)}`,
      color: statusColor,
      //.classNames: "fc-custom-available-time",
      appointDate: date,
      startMinutes: period_from,
      endMinutes: period_to,
      period_type: "appointment",
      appoint_id: id,
    });
  }
  return data;
};

const getNewAvailabilities = (data, intervalTime, sessionTime) => {
  const newAvailableSpots = [];
  for (let i = 0; i < data.length; i++) {
    const workDays = data[i];
    const eventDay = workDays.date;
    const periods = handlePeriods(
      workDays.period_from,
      workDays.period_to,
      intervalTime,
      sessionTime
    );
    for (let k = 0; k < periods.length; k++) {
      newAvailableSpots.push({
        title: `Available time* (${sessionTime} min)`,
        start: `${eventDay}T${calMinutesToHours(periods[k].from)}`,
        end: `${eventDay}T${calMinutesToHours(periods[k].to)}`,
        color: "#0b6ab7",
        classNames: "fc-custom-available-time",
        appointDate: eventDay,
        startMinutes: periods[k].from,
        endMinutes: periods[k].to,
        period_type: "available",
      });
    }
  }
  return newAvailableSpots;
};

const matchNotAvailable = (data, spot) => {
  for (let i = 0; i < data.length; i++) {
    const e = data[i];
    if (
      (e.date === spot.appointDate &&
        spot.startMinutes >= e.period_from &&
        spot.startMinutes < e.period_to) ||
      (e.date === spot.appointDate &&
        spot.endMinutes >= e.period_from &&
        spot.endMinutes < e.period_to)
    ) {
      return true;
    }
  }
  return false;
};
