import React, { useState, useEffect } from "react";

import {
  EditField,
} from "../../common/editField";
import { updateAppoint } from "../../services/appointments";

function EditMatterID({ MatterID, appId, hasBeenUpdated }) {
  const [edit, setEdit] = useState(false);
  const [currentMatterID, setCurrentMatterID] = useState(MatterID);
  const [newMatterID, setNewMatterID] = useState(MatterID);
  const [errMsg, setErrMsg] = useState(false);

  const handleUpdate = async () => {
    try {
      await updateAppoint(appId, { MatterID: newMatterID });
      setCurrentMatterID(newMatterID);
      hasBeenUpdated();
    } catch (error) {
      setErrMsg(true);
      setTimeout(() => {
        setErrMsg(false);
      }, 4000);
    } finally {
      setEdit(false);
    }
  };

  const handleCancel = () => {
    setNewMatterID(currentMatterID);
    setEdit(false);
  };

  return (
    <div className="col-12">
      {!edit ? (
          <p>
          <strong>Matter ID:</strong>
          {currentMatterID}
          <span
            className="badge badge-primary ml-2 pointer"
            style={{ fontSize: "1em" }}
            onClick={() => setEdit(true)}
          >
            Edit
          </span>
        </p>
      ) : (
        <>
          <p className="mt-2">
            <strong>Matter ID:</strong>{" "}
            <input
              type="text"
              className="form-control mb-2"
              id="matterID"
              value={newMatterID}
              onChange={(e) =>
                setNewMatterID(e.target.value)
              }
            />
          </p>

          <span className="btn btn-success mr-2" onClick={handleUpdate}>
            Update
          </span>
          <span className="btn btn-danger" onClick={handleCancel}>
            Cancel
          </span>
        </>
      )}

      {errMsg && (
        <div class="alert alert-warning" role="alert">
          Something went wrong. Please try again.
        </div>
      )}
    </div>
  );
}

export default EditMatterID;
