import React, { useState, useEffect } from "react";
import format from "date-format";

function ReportLogs({ data, handleShowLogs }) {
  const [selectedLog, setSelectedLog] = useState();

  useEffect(() => {
    if (data && data.length >= 1) {
      setSelectedLog(data[0]);
    }
  }, []);

  return (
    <div className="row">
      <div className="col-5">
        <h5>Appointment Logs</h5>
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Subject</th>
              <th scope="col">Date</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item) => (
              <tr>
                <th className="pointer" onClick={() => setSelectedLog(item)}>
                  {item.email_subject}{" "}
                  {selectedLog &&
                  selectedLog.id &&
                  selectedLog.id === item.id ? (
                    <span className="badge badge-primary">Selected</span>
                  ) : (
                    <span className="badge badge-secondary">View details</span>
                  )}
                </th>
                <td>
                  {item.date && format("dd/MM/yyyy hh:mm", new Date(item.date))}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="col-7">
        {selectedLog && (
          <>
            <div
              dangerouslySetInnerHTML={{ __html: selectedLog.email_message }}
            />
            <div class="alert alert-info mt-4" role="alert">
              <ul className="m-0" className="list-style:none;">
                <li>
                  <span className="badge badge-info">To:</span>{" "}
                  {selectedLog.email_to || "-"}
                </li>
                <li>
                  <span className="badge badge-info">Cc:</span>{" "}
                  {selectedLog.email_cc || "-"}
                </li>
                <li>
                  <span className="badge badge-info">Bcc:</span>{" "}
                  {selectedLog.email_bcc || "-"}
                </li>
                <li>
                  <span className="badge badge-info">Date/Time:</span>{" "}
                  {selectedLog.date &&
                    format("dd/MM/yyyy hh:mm", new Date(selectedLog.date))}
                </li>
              </ul>
            </div>
          </>
        )}
      </div>
      <div className="col-12">
        <button className="btn btn-secondary" onClick={handleShowLogs}>
          Close
        </button>
      </div>
    </div>
  );
}

export default ReportLogs;
