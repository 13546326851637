import React from "react";
import _ from "lodash";

export function EditField({
  edit,
  initialValue,
  currentValue,
  setData,
  id,
  label,
  type,
}) {
  if (!edit) {
    return (
      <>
        <span className="d-block">
          <strong>{label}:</strong>
        </span>
        {initialValue}
      </>
    );
  } else {
    return (
      <>
        <span className="d-block">
          <strong>{label}:</strong>
        </span>
        <input
          type={type ? type : "text"}
          id={id}
          value={currentValue}
          onChange={(e) => setData(id, e.target.value)}
          className="ml-1"
        />
      </>
    );
  }
}

export function EditTextArea({
  edit,
  initialValue,
  currentValue,
  setData,
  id,
  type,
  label,
}) {
  if (!edit) {
    return (
      <>
        <span className="d-block">
          <strong>{label}:</strong>
        </span>
        {initialValue}
      </>
    );
  } else {
    return (
      <>
        <span className="d-block">
          <strong>{label}:</strong>
        </span>
        <textarea
          type={type ? type : "text"}
          id={id}
          onChange={(e) => setData(id, e.target.value)}
          className="ml-1 w-100"
        >
          {initialValue}
        </textarea>
      </>
    );
  }
}

export function EditSelect({
  edit,
  initialValue,
  currentValue,
  setData,
  id,
  list,
  label,
}) {
  if (!edit) {
    return (
      <>
        <span className="d-block">
          <strong>{label}:</strong>
        </span>{" "}
        {initialValue}
      </>
    );
  } else {
    return (
      <>
        {list && (
          <>
            <span className="d-block">
              <strong>{label}:</strong>
            </span>
            <select
              class="form-control"
              value={currentValue}
              id={id}
              onChange={(e) => setData(id, e.target.value)}
            >
              {list.map((item, index) => (
                <option value={item.name} key={index}>
                  {item.name}
                </option>
              ))}
            </select>
          </>
        )}
      </>
    );
  }
}

export function EditMultiSelect({
  edit,
  initialValue,
  currentValue,
  setData,
  id,
  list,
  label,
}) {
  if (!edit) {
    return (
      <>
        <span className="d-block">
          <strong>{label}:</strong>
        </span>{" "}
        {initialValue}
      </>
    );
  } else {
    return (
      <>
        {list && (
          <>
            <span className="d-block">
              <strong>{label}:</strong>
            </span>
            {list.map((item, index) => (
              <div
                class="custom-control custom-checkbox"
                key={index}
                onClick={() => setData(id, item.name)}
              >
                <input
                  type="checkbox"
                  class="custom-control-input"
                  checked={
                    Array.isArray(currentValue) &&
                    currentValue.findIndex(
                      (element) => element === item.name
                    ) !== -1
                      ? true
                      : false
                  }
                  id={index}
                />
                <label class="custom-control-label d-block" for="customCheck1">
                  {item.name}
                </label>
              </div>
            ))}
          </>
        )}
      </>
    );
  }
}

//export default EditField, EditSelect };
