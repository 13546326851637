import axios from "axios";
import { apiURL } from "../config.json";

const apiEndPoint = `${apiURL}/appointments`;

export async function getById(id) {
  const response = await axios.get(apiEndPoint + "/" + id);
  return response.data;
}

export async function updateAppoint(id, data) {
  const response = await axios.put(apiEndPoint + "/" + id, data);
  return response.data;
}

export async function updateAppointStatus(id, status, userdetails) {
  const response = await axios.put(apiEndPoint + "/status/" + id, {
    status,
    userdetails,
  });
  return response.data;
}

export async function updateAppointDateTime(id, data) {
  const response = await axios.put(apiEndPoint + "/datetime/" + id, {
    ...data,
  });
  return response.data;
}

export async function updateAppointClient(id, data) {
  const response = await axios.put(apiEndPoint + "/client/" + id, { ...data });
  return response.data;
}

export async function updateAppointLawFirm(id, data) {
  const response = await axios.put(apiEndPoint + "/lawfirm/" + id, { ...data });
  return response.data;
}

export async function createAppoint(data) {
  const response = await axios.post(apiEndPoint + "/admin-create-appoint", {
    ...data,
  });
  return response.data;
}

export default {
  getById,
  updateAppoint,
  updateAppointStatus,
  updateAppointDateTime,
  updateAppointClient,
  updateAppointLawFirm,
  createAppoint,
};
