import React, { useState, useEffect } from "react";
import { ContentState, convertToRaw, convertFromHTML } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import { convertToHTML } from "draft-convert";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const TextEditor = (props) => {
  let messageData = props.message.replace(/<figure/g, "<span");
  const blocksFromHTML = convertFromHTML(messageData);

  const [contentState, setContentState] = useState(
    convertToRaw(
      ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      )
    )
  );

  const handleEditorChange = (state) => {
    setContentState(state);
    convertContentToHTML();
  };

  const convertContentToHTML = () => {
    try {
      if (contentState && contentState.getCurrentContent()) {
        let currentContentAsHTML = convertToHTML(
          contentState.getCurrentContent()
        );
        props.handleChange(currentContentAsHTML);
      }
    } catch (error) {
      // console.log(error);
    }
  };

  useEffect(() => {}, []);

  return (
    <div>
      <Editor
        defaultContentState={contentState}
        onEditorStateChange={handleEditorChange}
      />
    </div>
  );
};

export default TextEditor;
