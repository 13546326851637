import React from "react";
import { AmplifySignOut } from "@aws-amplify/ui-react";
import { Link } from "react-router-dom";

function Sidebar(props) {
  return (
    <div className="left side-menu">
      <div className="slimscroll-menu" id="remove-scroll">
        <div id="sidebar-menu">
          <ul className="metismenu" id="side-menu">
            <li className="menu-title">Menu</li>

            <li>
              <Link to="/calendar" className="waves-effect">
                <i className="dripicons-calendar"></i>
                <span> Calendar </span>
              </Link>
            </li>

            <li>
              <Link to="/doctors" className="waves-effect">
                <i className="dripicons-clipboard"></i>
                <span> Doctors </span>
              </Link>
            </li>

            <li>
              <Link to="/email-templates" className="waves-effect">
                <i className="dripicons-clipboard"></i>
                <span> Email Template </span>
              </Link>
            </li>

            <li>
              <Link to="/user-management" className="waves-effect">
                <i className="dripicons-user-group"></i>
                <span> User Management </span>
              </Link>
            </li>
          </ul>
        </div>

        <div className="clearfix"></div>
      </div>
      <div style={{ position: "absolute", bottom: "0px", width: "100%" }}>
        <AmplifySignOut />
      </div>
    </div>
  );
}

export default Sidebar;
