import React, { useEffect, useState } from "react";
import { calMinutesToHours } from "../../common/time";
import _ from "lodash";
import format from "date-format";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  getById,
  updateAppoint,
  updateAppointClient,
  updateAppointLawFirm,
} from "../../services/appointments";
import EditAdminComments from "./editAdminComments";
import EditMatterID from "./editMatterID";
import EditDateTime from "./editDateTime";
import EditStatus from "./editStatus";
import EditClient from "./editClient"; 
import EditLawFirm from "./editLawFirm";
import CreateEmail from "./createEmail";
import EditLocation from "./editLocation";

function AppointmentDetails({ appId, hasBeenUpdated }) {
  const [appoint, setAppoint] = useState();
  const [errMessage, setErrMessage] = useState({});
  const [loadSendEmail, setLoadSendEmail] = useState(false);

  const fetchAppointment = async () => {
    try {
      let vdata = await getById(appId);
      setAppoint(vdata);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!isNaN(appId) && appId !== 0) {
      fetchAppointment();
    }
  }, [appId]);

  const handleClientUpdate = async (data) => {
    try {
      await updateAppointClient(appId, data);
      fetchAppointment();
      return;
    } catch (error) {
      setErrMessage({
        section: "client",
        message: "Error: Data couldn't be updated!",
      });
      setTimeout(() => {
        setErrMessage({});
      }, 4000);
      return;
    }
  };

  const handleLawFirmUpdate = async (data) => {
    try {
      await updateAppointLawFirm(appId, data);
      fetchAppointment();
      return;
    } catch (error) {
      setErrMessage({
        section: "lawFirm",
        message: "Error: Data couldn't be updated!",
      });
      setTimeout(() => {
        setErrMessage({});
      }, 4000);
      return;
    }
  };

  const handleLocationUpdate = async (data) => {
    try {
      await updateAppoint(appId, data);
      fetchAppointment();
      return;
    } catch (error) {
      setErrMessage({
        section: "client",
        message: "Error: Data couldn't be updated!",
      });
      setTimeout(() => {
        setErrMessage({});
      }, 4000);
      return;
    }
  };

  return (
    <div>
      {" "}
      <ToastContainer />
      {appoint && (
        <div className="col-12 mb-4">
          <div className="border rounded border-primary p-4">
            {!loadSendEmail ? (
              <div className="row">
                <div className="col">
                  <p className="mb-0">
                    <strong>Customer Key:</strong> {appoint.appoint.customerkey}
                  </p>
                  <div className="row">
                    <EditMatterID
                      appId={appId}
                      MatterID={appoint.appoint.MatterID}
                      hasBeenUpdated={hasBeenUpdated}
                    />
                  </div>
                  <p className="mt-2 mb-2">
                    <strong>
                      {appoint.doc.title || ""} {appoint.doc.name || ""}
                    </strong>
                    {" - "}
                    {appoint.appoint.speciality}
                  </p>

                  <EditLocation
                    appoint={appoint}
                    updateData={handleLocationUpdate}
                    appId={appId}
                  />

                  <EditDateTime
                    appId={appId}
                    date={appoint.appoint.date}
                    period_from={appoint.appoint.period_from}
                    period_to={appoint.appoint.period_to}
                    hasBeenUpdated={hasBeenUpdated}
                  />
                  <p className="mt-2 d-none">
                    <strong>Date:</strong>{" "}
                    {format("dd/MM/yyyy", new Date(appoint.appoint.date))} at{" "}
                    <strong>Time:</strong>{" "}
                    {calMinutesToHours(appoint.appoint.period_from)} -{" "}
                    {calMinutesToHours(appoint.appoint.period_to)}
                  </p>

                  <>
                    <hr className="mt-2" />
                    <EditLawFirm
                      appoint={appoint}
                      updateData={handleLawFirmUpdate}
                      appId={appId}
                    />
                    {errMessage &&
                      errMessage.section &&
                      errMessage.section === "lawFirm" && (
                        <span className="text-danger">
                          {errMessage.message}
                        </span>
                      )}
                  </>

                  <>
                    <hr className="mt-2" />
                    <EditClient
                      appoint={appoint}
                      updateData={handleClientUpdate}
                      appId={appId}
                    />
                    {errMessage &&
                      errMessage.section &&
                      errMessage.section === "client" && (
                        <span className="text-danger">{errMessage.client}</span>
                      )}
                    <hr />
                    <div className="row">
                      <EditAdminComments
                        appId={appId}
                        adminComments={appoint.appoint.adminComments}
                        hasBeenUpdated={hasBeenUpdated}
                      />
                      <EditStatus
                        appId={appId}
                        status={appoint.appoint.status}
                        hasBeenUpdated={hasBeenUpdated}
                      />
                    </div>
                  </>
                </div>
              </div>
            ) : (
              <CreateEmail appointId={appId} />
            )}
            <div className="row">
              <div className="col mt-3">
                {loadSendEmail ? (
                  <span
                    className="btn btn-warning"
                    onClick={() => setLoadSendEmail(false)}
                  >
                    Show appointment details
                  </span>
                ) : (
                  <span
                    className="btn btn-warning"
                    onClick={() => setLoadSendEmail(true)}
                  >
                    Create email
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      )}{" "}
      {/*end details*/}
    </div>
  );
}

export default AppointmentDetails;
