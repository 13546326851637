import axios from "axios";
import { apiURL } from "../config.json";

const apiEndPoint = `${apiURL}/calendarsearch`;

export async function searchDocs(query) {
  try {
    const response = await axios.post(`${apiEndPoint}/doctors`, { query });
    return response.data;
  } catch (error) {
    return [];
  }
}

export default { searchDocs };
