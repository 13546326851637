import React, { useEffect, useState } from "react";
import Breadcrumb from "../layout/breadcrumb";
import {
  fetchTemplate,
  addTemplate,
  updateTemplate,
} from "../../services/emailTemplate";
import { useParams } from "react-router-dom";
import _ from "lodash";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TextEditor from "../../common/textEditor";
import ShowToken from "../../common/tokenCopy";

function EditTemplate() {
  const [data, setData] = useState({});
  const [notFoundError, setNotFoundError] = useState();
  const [templateId, setTemplateId] = useState(useParams().id);
  const [processing, setProcessing] = useState(false);

  const fetchData = async () => {
    try {
      const resp = await fetchTemplate(templateId);
      if (!resp) {
        setNotFoundError("Template not found...");
      }
      console.log(resp.result[0]);
      resp.status = resp.status.toString();
      resp.result[0].message = resp.result[0].message.replace(
        /<figure/g,
        "<span"
      );
      setData(resp.result[0]);
    } catch (error) {
      setNotFoundError("Template not found...");
    }
  };

  useEffect(() => {
    if (!isNaN(templateId) && templateId !== 0) {
      fetchData();
    }
  }, [templateId]);

  const handleAddTemplate = async () => {
    try {
      const result = await addTemplate(data);
      setTemplateId(result.id);
      toast.success("Template has been successfully created!");
    } catch (error) {
      toast.warn(`Something went wrong. Your data hasn't been registered.`);
    }
  };

  const handleUpdateTemplate = async () => {
    try {
      setProcessing(true);
      await updateTemplate(data, templateId);
      toast.success("Doctor has been updated!");
      setTimeout(() => {
        setProcessing(false);
      }, 200);
    } catch (error) {
      setProcessing(false);
      toast.warn(`Something went wrong. Your data hasn't been updated.`);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (templateId && templateId !== 0) {
      handleUpdateTemplate();
    } else {
      handleAddTemplate();
    }
  };

  const handleEditorChange = (message) => {
    setData({ ...data, message });
  };

  if (notFoundError) {
    return (
      <div className="content">
        <div className="container-fluid">
          <div>Something went wrong! {notFoundError}</div>
        </div>
      </div>
    );
  }
  return (
    <div className="content">
      <div className="container-fluid">
        <div className="content">
          <div className="container-fluid">
            <ToastContainer />
            <Breadcrumb
              title="Manage Template"
              path={[
                { title: "Home", url: "/" },
                { title: "Email Templates", url: "/email-templates" },
              ]}
            />
            <div className="row justify-content">
              <div className="col-md-12">
                <div class="card">
                  <div class="card-body">
                    <h4 class="mt-0 header-title mb-4">Template Details</h4>
                    <form onSubmit={handleSubmit}>
                      <div className="row px-3">
                        <div className="col-md-8">
                          <div class="form-group">
                            <label>Title</label>
                            <input
                              type="text"
                              placeholder=""
                              class="form-control"
                              value={data.title || ""}
                              id="title"
                              onChange={(e) =>
                                setData({ ...data, title: e.target.value })
                              }
                              maxLength="255"
                            />
                            <span class="font-13 text-muted"></span>
                          </div>
                          <div class="form-group">
                            <label>Subject</label>
                            <input
                              type="text"
                              placeholder=""
                              class="form-control"
                              value={data.subject || ""}
                              id="subect"
                              onChange={(e) =>
                                setData({ ...data, subject: e.target.value })
                              }
                              maxLength="255"
                            />
                            <span class="font-13 text-muted"></span>
                          </div>
                          <div class="form-group">
                            <label>Message</label>

                            <div
                              style={{
                                border: "1px solid #ced4da",
                                borderRadius: "0.25rem",
                                padding: "10px",
                              }}
                            >
                              {data.message ? (
                                <>
                                  <TextEditor
                                    message={data.message}
                                    handleChange={handleEditorChange}
                                    key="0"
                                  />
                                </>
                              ) : (
                                <TextEditor
                                  message=""
                                  handleChange={handleEditorChange}
                                  key="1"
                                />
                              )}
                            </div>
                            <span class="font-13 text-muted"></span>
                          </div>

                          <div class="form-group">
                            <label>Cc</label>
                            <input
                              type="text"
                              placeholder=""
                              class="form-control"
                              value={data.cc || ""}
                              id="cc"
                              onChange={(e) =>
                                setData({ ...data, cc: e.target.value })
                              }
                              maxLength="255"
                            />
                            <span class="font-13 text-muted">
                              Eg. email_1@email.com;email_2@email.com
                            </span>
                          </div>

                          <div class="form-group">
                            <label>Bcc</label>
                            <input
                              type="text"
                              placeholder=""
                              class="form-control"
                              value={data.bcc || ""}
                              id="bcc"
                              onChange={(e) =>
                                setData({ ...data, bcc: e.target.value })
                              }
                              maxLength="255"
                            />
                            <span class="font-13 text-muted">
                              Eg. email_1@email.com;email_2@email.com
                            </span>
                          </div>

                          <div class="form-group">
                            <label>Status</label>
                            <select
                              class="form-control"
                              value={data.status || "draft"}
                              onChange={(e) =>
                                setData({ ...data, status: e.target.value })
                              }
                              id="status"
                            >
                              <option value="draft">Draft</option>
                              <option value="active">Active</option>
                            </select>
                            {!processing ? (
                              <button className="btn btn-primary mt-3">
                                Save
                              </button>
                            ) : (
                              <span className="border mt-3 p-2 ">
                                <lord-icon
                                  src="https://cdn.lordicon.com/qutaqaes.json"
                                  trigger="loop"
                                  colors="primary:#545454,secondary:#1c75bc"
                                  stroke="51"
                                  style={{ width: "50px", height: "50px" }}
                                ></lord-icon>
                                Processing...
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="row">
                            <div className="col bg-light">
                              <h4>Tokens</h4>

                              <hr />
                              <h5>Appointment Details</h5>
                              <ul>
                                <li className="mb-2">
                                  Appointment Id:{" "}
                                  <ShowToken item="appoint.id" />
                                </li>
                                <li className="mb-2">
                                  Customer Key:{" "}
                                  <ShowToken item="appoint.customerkey" />
                                </li>
                                <li className="mb-2">
                                  Doctor name:{" "}
                                  <ShowToken item="appoint.doctor" />
                                </li>
                                <li className="mb-2">
                                  Speciality:{" "}
                                  <ShowToken item="appoint.speciality" />
                                </li>
                                <li className="mb-2">
                                  Location:{" "}
                                  <ShowToken item="appoint.location" />
                                </li>
                                <li className="mb-2">
                                  Date and Period:{" "}
                                  <ShowToken item="appoint.period" />
                                </li>
                                <li className="mb-2">
                                  Status: <ShowToken item="appoint.status" />
                                </li>
                              </ul>
                              <hr />
                              <h5>Law firm and Lawyer details</h5>
                              <ul>
                                <li className="mb-2">
                                  Name: <ShowToken item="lawfirm.name" />
                                </li>
                                <li className="mb-2">
                                  Reference:{" "}
                                  <ShowToken item="lawfirm.reference" />
                                </li>
                                <li className="mb-2">
                                  Lawyer: <ShowToken item="lawfirm.lawyer" />
                                </li>
                                <li className="mb-2">
                                  Phone: <ShowToken item="lawfirm.phone" />
                                </li>
                                <li className="mb-2">
                                  Email: <ShowToken item="lawfirm.email" />
                                </li>
                                <li className="mb-2">
                                  Address: <ShowToken item="lawfirm.address" />
                                </li>
                              </ul>
                              <hr />
                              <h5>Client and Claim details</h5>
                              <ul>
                                <li className="mb-2">
                                  Name: <ShowToken item="patient.name" />
                                </li>
                                <li className="mb-2">
                                  Phone: <ShowToken item="patient.phone" />
                                </li>
                                <li className="mb-2">
                                  Email: <ShowToken item="patient.email" />
                                </li>
                                <li className="mb-2">
                                  Date of Birth:{" "}
                                  <ShowToken item="patient.dtbirth" />
                                </li>
                                <li className="mb-2">
                                  Accident Date:{" "}
                                  <ShowToken item="patient.accidentdate" />
                                </li>
                                <li className="mb-2">
                                  Claim type:{" "}
                                  <ShowToken item="patient.claimtype" />
                                </li>
                                <li className="mb-2">
                                  Injury: <ShowToken item="patient.injury" />
                                </li>
                                <li className="mb-2">
                                  Comments:{" "}
                                  <ShowToken item="patient.comments" />
                                </li>
                              </ul>
                              <hr />
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditTemplate;
