import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import _ from "lodash";
import format from "date-format";

import TextEditor from "../../common/textEditor";
import { listTemplates, sendEmail } from "../../services/emailTemplate";
import { listLog } from "../../services/log";
import ReportLogs from "./ReportLogs";

function CreateEmail(props) {
  const [templateList, setTemplateList] = useState();
  const [selectedTemplate, setSelectedTemplate] = useState();
  const [processing, setProcessing] = useState(false);
  const [emailData, setEmailData] = useState({
    appointId: props.appointId,
    to: { admin: true, doctor: false, lawyer: false, patient: false },
    cc: "",
    bcc: "",
    subject: "",
    message: "",
  });
  const [emailLog, setEmailLog] = useState();

  const [reloadEditor, setReloadEditor] = useState(false);
  const [sentLog, setSentLog] = useState();

  const [showLogs, setShowLogs] = useState(false);

  const resetContent = () => {
    setSelectedTemplate();
    setProcessing(false);
    setEmailData({
      appointId: props.appointId,
      to: { admin: true, doctor: false, lawyer: false, patient: false },
      cc: "",
      bcc: "",
      subject: "",
      message: "",
    });
    setReloadEditor(true);
    setReloadEditor(false);
  };

  const fetchTemplates = async () => {
    const data = await listTemplates("active");
    setTemplateList(data);
  };

  const fetchEmailLog = async () => {
    try {
      const result = await listLog(props.appointId);
      if (result) {
        setEmailLog(result);
      }
    } catch (error) {
      setEmailLog();
    }
  };

  const loadTemplate = () => {
    const template = templateList.find(
      (item) => item.id === parseInt(selectedTemplate)
    );
    setEmailData({
      ...emailData,
      subject: template.subject,
      message: template.message,
      cc: template.cc || "",
      bcc: template.bcc || "",
    });
    setReloadEditor(true);
    setTimeout(() => {
      setReloadEditor(false);
    }, 1);
  };

  const handleEditorChanges = (val) => {
    setEmailData({ ...emailData, message: val });
  };

  const handleSend = async () => {
    try {
      setProcessing(true);
      const userDetails = await Auth.currentUserInfo();

      const result = await sendEmail(emailData, {
        username: userDetails.username,
        email: userDetails.attributes.email,
        sub: userDetails.attributes.sub,
      });
      setProcessing(false);
      resetContent();
      setSentLog("Email successfully sent!");
      setEmailLog();
      fetchEmailLog();
      setTimeout(() => {
        setSentLog();
      }, 4000);
    } catch (error) {
      setProcessing(false);
      setSentLog("ERROR: Your message couldn't be sent!");
      setTimeout(() => {
        setSentLog();
      }, 4000);
    }
  };

  const handleShowLogs = () => {
    setShowLogs(!showLogs);
  };

  useEffect(() => {
    fetchTemplates();
    fetchEmailLog();
  }, []);

  return (
    <>
      {!showLogs ? (
        <div className="row">
          <div className="col-6">
            {templateList && (
              <p className="mb-0">
                <string>Select a template:</string>
                <br />
                <select
                  name="cars"
                  id="cars"
                  className="w-50 p-2 mr-2"
                  onChange={(e) => setSelectedTemplate(e.target.value)}
                >
                  <option value=""></option>
                  {templateList.map((item, index) => (
                    <option value={item.id} key={item.id}>
                      {item.title}
                    </option>
                  ))}
                </select>
                {selectedTemplate && (
                  <span className="btn btn-success mb-1" onClick={loadTemplate}>
                    Load this template
                  </span>
                )}
              </p>
            )}
            <p className="mt-2 mb-0">
              <strong>To:</strong>{" "}
              <input
                type="checkbox"
                checked={emailData.to.admin}
                onClick={() =>
                  setEmailData({
                    ...emailData,
                    to: { ...emailData.to, admin: !emailData.to.admin },
                  })
                }
              />{" "}
              Admin{" "}
              <input
                type="checkbox"
                checked={emailData.to.doctor}
                onClick={() =>
                  setEmailData({
                    ...emailData,
                    to: { ...emailData.to, doctor: !emailData.to.doctor },
                  })
                }
              />{" "}
              Doctor{" "}
              <input
                type="checkbox"
                checked={emailData.to.lawyer}
                onClick={() =>
                  setEmailData({
                    ...emailData,
                    to: { ...emailData.to, lawyer: !emailData.to.lawyer },
                  })
                }
              />{" "}
              Lawyer{" "}
              <input
                type="checkbox"
                checked={emailData.to.patient}
                onClick={() =>
                  setEmailData({
                    ...emailData,
                    to: { ...emailData.to, patient: !emailData.to.patient },
                  })
                }
              />{" "}
              Patient
            </p>
            <p className="mb-0">
              <string>Cc:</string>
              <br />
              <input
                type="text"
                value={emailData.cc}
                className="w-100"
                onChange={(e) =>
                  setEmailData({ ...emailData, cc: e.target.value })
                }
              />
            </p>
            <p className="mb-0">
              <string>Bcc:</string>
              <br />
              <input
                type="text"
                value={emailData.bcc}
                className="w-100"
                onChange={(e) =>
                  setEmailData({ ...emailData, bcc: e.target.value })
                }
              />
            </p>
          </div>
          <div className="col-6">
            <p className="mb-0">
              <string>Subject:</string>
              <br />
              <input
                type="text"
                value={emailData.subject}
                className="w-100"
                onChange={(e) =>
                  setEmailData({ ...emailData, subject: e.target.value })
                }
              />
            </p>
            <p className="border border-black mt-2 p-2">
              {!reloadEditor && (
                <TextEditor
                  message={emailData.message}
                  handleChange={handleEditorChanges}
                />
              )}
            </p>
            <p>
              {processing ? (
                <span className="border mt-3 p-2 ">
                  <lord-icon
                    src="https://cdn.lordicon.com/qutaqaes.json"
                    trigger="loop"
                    colors="primary:#545454,secondary:#1c75bc"
                    stroke="51"
                    style={{ width: "50px", height: "50px" }}
                  ></lord-icon>
                  Processing...
                </span>
              ) : (
                <>
                  {sentLog ? (
                    <>{sentLog}</>
                  ) : (
                    <span
                      className="btn btn-success w-100"
                      onClick={handleSend}
                    >
                      Send
                    </span>
                  )}
                </>
              )}
            </p>
            {emailLog && emailLog.length > 0 && (
              <>
                <hr />
                <span
                  className="mt-3 border border-info text-info d-block text-center p-2 rounded pointer"
                  onClick={handleShowLogs}
                >
                  <u>Total emails sent: {emailLog.length}</u>
                  <br />
                  Last email{" "}
                  <strong>
                    {" "}
                    {emailLog &&
                      emailLog[0] &&
                      emailLog[0].date &&
                      format("dd/MM/yyyy hh:mm", new Date(emailLog[0].date))}
                  </strong>
                </span>
              </>
            )}
          </div>
        </div>
      ) : (
        <ReportLogs data={emailLog} handleShowLogs={handleShowLogs} />
      )}
    </>
  );
}

export default CreateEmail;
